import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebService } from './../../provider/web.service';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  loading: boolean;
  passwordSubmit: boolean;
  resetkey: any;
  public passwordForm: FormGroup;
  funcName: any;
  passwordMsg: any;
  keyState: boolean;
  keyStatemsg: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {
    this.keyState = false;
  }

  ngOnInit() {
    this.resetkey = decodeURIComponent(this.route.snapshot.params['id']);
    this.passwordForm = this.formBuilder.group({
      'newpassword': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'cpassword': ['', Validators.compose([Validators.required]), this.MatchPassword.bind(this)]
    });

    this.funcName = 'keyValid';
    const postData = {'resetkey': this.resetkey};
    this.service.post_data(this.funcName, postData).subscribe(response => {
      this.loading = false;
      if ( response.status === 'success') {
        this.keyState = false;
        this.keyStatemsg = '';
      } else {
        this.keyState = true;
        this.keyStatemsg = '<big class="text-danger">' + response.message + '</big>';
      }
    }, (error) => {
      this.keyStatemsg = '<big class="text-danger"> Something went wrong. Feel free to contact us .</big>';
    });
  }

  MatchPassword(val: any) {
    return new Promise(resolve => {
      if (val.value === this.passwordForm.value.newpassword) {
        resolve(null);
      } else {
        resolve({ 'match': false });
      }
    });
  }

  passwordProcess() {
    this.passwordSubmit = true;
    if (this.passwordForm.valid) {
      const password_data = this.passwordForm.value;
      const This = this;

      const postData = {
        'newpassword': password_data.newpassword,
        'resetkey': this.resetkey,
      };

      this.funcName = 'resetPassword';


      this.loading = true;
      this.service.post_data(this.funcName, postData).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          this.passwordMsg = '<small class="text-info">' + response.message + '</smal>';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
        } else {
          this.passwordMsg = '<small class="text-danger">' + response.message + '</smal>';
        }
        setTimeout(function() {
          This.passwordMsg = '';
        }, 3000);
      }, (error) => {
        this.passwordMsg = '<small class="text-danger"> Something went wrong. Feel free to contact us .</smal>';
      });
    }
  }

}
