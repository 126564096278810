import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './home/account/account.component';
import { AuthGuard } from './provider/auth.guard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CancelsuccessComponent } from './cancelsuccess/cancelsuccess.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { IndexagentComponent } from './home/indexagent/indexagent.component';
const appRoutes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'cancelsuccess', component: CancelsuccessComponent },
  {path : 'aboutus' , component: AboutusComponent},
  {path : 'agent' , component: IndexagentComponent}

];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule,TranslateModule]
})
export class AppRoutingModule { }
