import { Component, OnInit, HostListener, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../provider/validation.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router, private vs: ValidationService) { }

  ngOnInit() {
  }

  goToPage(path, data = null) {
    console.log(path);
    this.router.navigateByUrl(path, { queryParams: data });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  toogle(value) {
    this.vs.getNavbar(value);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
