import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {TranslateService} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors
} from '@angular/forms';
import {
  trigger,
  transition,
  state,
  animate,
  style
} from '@angular/animations';
import { ValidationService } from './../../provider/validation.service';
import { WebService } from './../../provider/web.service';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  startWith
} from 'rxjs/operators';

@Component({
  selector: 'app-index',
  templateUrl: './indexagent.component.html',
  styleUrls: ['./indexagent.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(100%)'
        }),
        animate(
          '600ms ease-in',
          style({
            transform: 'translateY(0%)'
          })
        )
      ]),
      transition(':leave', [
        style({
          transform: 'translateY(0%)'
        }),
        animate(
          '600ms ease-out',
          style({
            transform: 'translateY(100%)'
          })
        )
      ])
    ]),
    trigger('slideInNew', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ]),
    trigger('slideInFeed', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})

export class IndexagentComponent implements OnInit {
  submitForm: any;
  userData: any;
  source_field: any;
  homeForm: FormGroup;
  cancelForm: FormGroup;
  feedbackForm: FormGroup;
  results: any[];
  funcName: any;
  ret_journey:any;
  desfuncName: any;
  searchSubmit: boolean;
  feedbackSubmit: boolean;
  sourceFlag: boolean;
  destResults: any[];
  destFlag: boolean;
  main_error: boolean;
  today: any;
  source_state: boolean;
  dest_state: boolean;
  sourceLoad: boolean;
  destload: boolean;
  promocodedata: any;
  userID: any;
  cancel_error: boolean;
  cancel_err_msg: any;
  isShow = false;
  agencyList: any;
  loading: boolean;
  ticketId: any;
  trackingData: any;
  trackError: any;
  track_error: any;
  trackTicket: any;
  cancel_submit:any;

  @Input() currentState;
  ticketCancelFlag: boolean;
  displayCancelFlag = false;
  cancelTicketData: any;
  adslideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true
  };

  whoareweConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  offerConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };
  shownGroup: number;
  selectedCountry: any;
  showCountry: boolean;
  countrylist: any;
  onwardJourneyDate: any;
  country: any;

  slickGoTo() {
    alert('hi');
  }
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) { }

  beforeChange(e) {
    console.log('beforeChange');
  }
  constructor(
    private translate: TranslateService,
    private router: Router,
    private MatSlideToggleModule:MatSlideToggleModule,
    private route: ActivatedRoute,
    private _localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public service: WebService
  ) {
    this.getCountryList();
    this.submitForm = false;
    this.getUserData();
    this.trackError = false;
    this.getPromoData();
    this.getAgentsList();
    this.loading = false;
   
    this.shownGroup = 0;
    this.cancelTicketData = '';
    this._localeService.use('engb');
    this.translate.get('warnings').subscribe((data: any) => {
      this.country = data;
    });
    this.homeForm = this.formBuilder.group({
      fromStationId: ['', Validators.compose([Validators.required])],
      toStationId: ['', Validators.compose([Validators.required])],
      travelDate: ['', Validators.compose([Validators.required])],
      returnDate: [''],
      sourceName: ['', Validators.compose([Validators.required])],
      destinationName: ['', Validators.compose([Validators.required])]
    });
    this.cancelForm = this.formBuilder.group({
      bookingID: ['', Validators.required],
     // email_phone: ['', Validators.required],
      // type: ['', Validators.compose([Validators.required])],
      // userId: ['', Validators.required]
    });
    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email_phone: ['', Validators.compose([Validators.required])],
      feedback: ['', Validators.compose([Validators.required])]
    });
    this.searchSubmit = false;
    this.feedbackSubmit =false;
    this.sourceFlag = false;
    this.main_error = false;
    this.source_state = false;
    this.dest_state = false;
    this.sourceLoad = false;
    this.destload = false;
    this.destFlag = false;
    this.cancel_error = false;
    this.today = new Date();
    this.displayCancelFlag = false;

    this.vs.getNavstatus().subscribe((data) => {
      console.log('Status', data);
      if (data.text === 5) {
        this.togglesubmitForm();
      } else {
        this.toggleGroup(data.text);
      }


    });

  }
  togglesubmitForm() {
    this.submitForm = !this.submitForm;
    this.feedbackSubmit =false;
  }

  getUserData() {
    this.userData = JSON.parse(this.service.getLocalStorageItem('userData'));
    console.log(this.userData);
    if (this.userData) {
      this.userID = this.userData.id;
    }
  }
  isShowCancelPopup() {
    this.isShow = !this.isShow;
  }

  isGroupShown(group) {
    return this.shownGroup === group;
  }
  toggleGroup(group) {
    this.ticketId = null;
    this.cancelForm.reset();
    this.displayCancelFlag = false;
    console.log('Group', group);
    if (this.isGroupShown(group)) {
      this.shownGroup = group;
    } else {
      this.shownGroup = group;
    }
    this.cancel_submit = false;
  }
  ngOnInit() {
    this.displayCancelFlag = false;
    this.cancel_submit = false;
    this.homeForm.get('travelDate').valueChanges.pipe(
      debounceTime(400),
        distinctUntilChanged(),
        switchMap(query =>
         this.onwardJourneyDate = query
        ));
    // this.service.get_data('get_promocode').subscribe(response=> {
    //   if (response.status === 'success') {
    //      this.promocodedata = this.service.ObjArray(response.data);
    //   }
    //   console.log('Promocodedata', this.promocodedata);
    // });
    this.funcName = 'source_location/';
    this.homeForm
      .get('sourceName')
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(query =>
          this.service.get_data(
            this.funcName + this.filterQuery(query, 'source') + '/' + (this.selectedCountry)
          )
        )
      )
      .subscribe(response => {
        console.log(this.source_state);
        this.sourceLoad = false;
        if (response.status === 'success' && this.source_state === true) {
          this.results = response.data;
          console.log(this.results);
          if (this.results.length > 0) {
            this.sourceFlag = true;
          } else {
            this.sourceFlag = false;
          }
          console.log(this.sourceFlag);
        } else {
          this.results = [];
          this.sourceFlag = false;
        }
      });

    this.desfuncName = 'destination_location/';
    this.homeForm
      .get('destinationName')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(query =>
          this.service.get_data(
            this.desfuncName +
            this.filterQuery(query, 'dest') +
            '/' +
            this.homeForm.controls['fromStationId'].value
          )
        )
      )
      .subscribe(response => {
        this.destload = false;
        if (response.status === 'success' && this.dest_state === true) {
          this.destResults = response.data;
          console.log(this.results);
          if (this.destResults.length > 0) {
            this.destFlag = true;
            const newArr = [];
            this.destResults.forEach((item, index) => {
              if (newArr.findIndex(i => i.Value == item.Value) === -1) {
                newArr.push(item);
              }
            });
            this.destResults = newArr;
          } else {
            this.destFlag = false;
          }
          console.log(this.destFlag);
        } else {
          this.destResults = [];
          this.destFlag = false;
        }
      });
  }

  filterQuery(query, type: string) {
    if (type === 'source') {
      this.sourceLoad = true;
    } else {
      this.destload = true;
    }
    if (query !== null) {
      return query.replace(/[^a-zA-Z ]/g, '');
    } else {
      return '';
    }
  }

  select_source(data: any) {
    this.sourceFlag = false;
    this.source_state = false;
    this.homeForm.controls['sourceName'].patchValue(data.stationName);
    this.homeForm.controls['fromStationId'].patchValue(data.stationCode);
  }

  select_dest(data: any) {
    this.destFlag = false;
    this.dest_state = false;
    this.homeForm.controls['destinationName'].patchValue(data.stationName);
    this.homeForm.controls['toStationId'].patchValue(data.stationCode);
  }

  onChanges(): void { }

  bus_search() {
    this.main_error = false;
    this.searchSubmit = true;
    const response = this.homeForm.value;
    const This = this;
    console.log(this.homeForm.value);
    if (this.homeForm.valid) {
      const searchObj = encodeURIComponent(JSON.stringify(this.homeForm.value));
      this.router.navigate(['searchresult/' + searchObj]);
    } else {
      const data = this.homeForm.value;
      if (
        data.sourceName !== '' &&
        data.destinationName !== '' &&
        data.travelDate !== ''
      ) {
        if (data.fromStationId === '' || data.toStationId === '') {
          this.main_error = true;
        }
      }
    }
  }

  initCalled() {
    this.sourceFlag = false;
    this.destFlag = false;
    console.log(this.source_state);
  }
  search(value) {
    this.onwardJourneyDate = value;
  }

  resetFun() {
    this.searchSubmit = false;
    this.feedbackSubmit = false;
    this.homeForm.reset();
  }
  goToPage(path: any, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  min_return() {
    const d = this.homeForm.get('travelDate').value;
    return d;
  }

  value_change(type: string) {
    console.log(this.source_state);
    if (type === 'source') {
      this.source_state = true;
    } else {
      this.dest_state = true;
    }
  }
  cancelTicket() {
    this.isShow = false;
    this.cancel_error = false;
    if (this.userID && this.userData) {
      this.cancelForm.patchValue({
        userId: this.userID,
        type: this.userData.type
      });
      this.loading = true;
      if (this.cancelForm.valid) {
        this.service.post_data('cancelBooking', this.cancelForm.value).subscribe((response: any) => {
          this.loading = false;
          console.log(response);
          if (response.status === 'success') {
            this.confirmCancelTicket(response.data.cancelCode);
          } else {
            this.displayCancelFlag = true;
            this.ticketCancelFlag = false;
            this.cancel_error = true;
            this.cancel_err_msg =response.message
          }
        });
      }else{
        alert("else")
      }
    } else {
      this.displayCancelFlag = true;
      this.ticketCancelFlag = false;
    }
  }
  confirmCancelTicket(id) {
    this.loading = true;
    const passData = { 'cancelCode': id };
    this.service.post_data('cancel_confirm', passData).subscribe((data: any) => {
      this.loading = false;
      if (data.status === 'success') {
        this.displayCancelFlag = true;
        this.ticketCancelFlag = true;
      } else {
        this.displayCancelFlag = true;
        this.ticketCancelFlag = false;
      }
    });
  }
  getPromoData() {
    this.service.get_data('get_promocode').subscribe(response => {
      if (response.status === 'success') {
        this.promocodedata = this.service.ObjArray(response.data);
        console.log('PromoCode', this.promocodedata);
      }
      console.log('Promocodedata', this.promocodedata);
    });
  }
  getAgentsList() {
    this.service.get_data('getAgencyList').subscribe((response: any) => {
      if (response.code === 1) {
        this.agencyList = this.service.ObjArray(response.data);
      } else {
        this.agencyList = null;
      }
    });
  }
  trackBus() {
    console.log(this.ticketId);
    this.trackError = false;
    if (this.ticketId) {
      console.log('TicketId', this.ticketId);
      const passData = {
        bookingId: this.ticketId
      };
      this.loading = true;
      this.service
        .post_data('getBusLocation', passData)
        .subscribe((data: any) => {
          this.loading = false;
          console.log(data);
          if (data.code === 0) {
            this.trackError = true;
            this.track_error = data.message;
          } else {
            if (data.data.trackingStatus === '1') {
              this.goToPage(
                '/bustracking/' + encodeURIComponent(JSON.stringify(data.data))
              );
            } else {
              this.trackError = true;
              this.track_error = data.message;
            }
          }

        });
    } else {
      this.track_error = 'Please Enter Booking ID';
      console.log('TicketId', this.ticketId);
    }
  }
  trackParcel() {
    console.log(this.ticketId);
    this.trackError = false;
    if (this.ticketId) {
      console.log('TicketId', this.ticketId);
      const passData = {
        parcelId: this.ticketId
      };
      this.loading = true;
      this.service
        .post_data('getParcelLocation', passData)
        .subscribe((data: any) => {
          this.loading = false;
          console.log(data);
          if (data.code === 0) {
            this.trackError = true;
            this.track_error = data.message;
          } else {
            if (data.code === 1) {
              this.goToPage(
                '/bustracking/' + encodeURIComponent(JSON.stringify(data.data))
              );
            } else {
              this.trackError = true;
              this.track_error = this.country.Norecordfound;
            }
          }

        });
    } else {
      this.track_error = 'Please Enter Booking ID';
      console.log('TicketId', this.ticketId);
    }
  }
  getTripDetails() {
    this.cancel_submit = true;
    this.cancelTicketData = '';
    const passData = {
      bookingCode: this.cancelForm.value.bookingID
    };
    if (this.cancelForm.valid){
      
      this.loading = true;
      this.service.post_data('getBookingDetails', passData).subscribe((data: any) => {
        this.loading = false;
        console.log(data);
        if (data.code === 0) {
          this.displayCancelFlag = true;
          this.cancel_err_msg = data.errorDesc;
          this.ticketCancelFlag = false;
        } else {
          this.displayCancelFlag = false;
          this.isShow = true;
          this.cancelTicketData = data;
         
        }
      });

    }else{
      console.log('this.cancelForm', this.cancelForm.value);
    }
  
  }
  submitFeedbackForm() {
    this.feedbackSubmit =true;
    if (this.feedbackForm.valid) {
      this.loading = true;
      this.service.post_data('feedback', this.feedbackForm.value).subscribe((data: any) => {
        this.loading = false;
        alert(data.message);
        this.togglesubmitForm();
        this.feedbackSubmit =false; 
        this.feedbackForm.reset();
      });
    }

  }
  submitCountry() {
    if(this.selectedCountry != null) {
      this.service.setLocalStorageItem('userCountry', JSON.stringify(this.selectedCountry));
      this.showCountry = false;
    }
  }

     getCountryList() {
    if(this.service.getLocalStorageItem('userCountry')) {
      this.showCountry = false;
      this.selectedCountry = JSON.parse(this.service.getLocalStorageItem('userCountry'));
      console.log('Slelect country'  , this.selectedCountry);
     } else {
        this.showCountry = true;
        this.service.get_data('getCountries').subscribe((data: any) => {
          this.countrylist  = data.data;
          });
      }
      }
      choose_country(value) {
        console.log(value);
        this.selectedCountry  = value;
        const passData = {
          country_code: this.selectedCountry
        };
         this.service.post_data('countryDetails',passData).subscribe((data) => {
         this.service.setLocalStorageItem('currency',JSON.stringify(data));
        });
      }
      return_journey(event:any){
       if(event.checked == true){
        this.ret_journey =true;
        }else{
        this.ret_journey =false;
        }
      }
}
