import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private subject = new Subject<any>();
  errorList = {
    'required': 'This field is required',
    'email': 'Please enter a valid email',
    'phone': 'Please enter a valid phone number',
    'password': 'Please enter a valid password',
    'minLength': 'Minimum characters length is',
    'maxLength': 'Maximum characters length is',
    'min': 'Age min start at ',
    'max': 'Age max end at ',
    'confirm': 'Password mismatch'
  };
  status: any;

  constructor() { }

  getErrorList() {
    return this.errorList;
  }

  getError(error: any): string {
    if (this.errorList.hasOwnProperty(error.code)) {  // check whether the error code having our definition
      return this.errorList[error.code]; // return our definition
    } else {
      return error.message;
    }
  }
  getNavbar(status: any) {
    this.subject.next({ text: status });
  }
  getNavstatus(): Observable<any> {
    return this.subject.asObservable();
  }
  getwindowRef() {
    return window;
  }
}
