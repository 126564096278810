import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { WebService } from './../../provider/web.service';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  bookinfoData: any;
  lang_choose:any;
  loading: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    const data = decodeURIComponent(this.route.snapshot.params['id']);
    this.bookinfoData = JSON.parse(data);
    console.log(this.bookinfoData);
  }
  downloadTciket(id) {
    this.loading = true;
    const lang =JSON.parse( this.service.getLocalStorageItem('userLang'));
    if(lang == 'fr'){
      this.lang_choose = 2
    } else{
      this.lang_choose = 1
    }
    
    const passData = {
      bookingCode: id,
       lang:this.lang_choose
    };
    this.service.post_data('pdf', passData).subscribe((response) => {
      this.loading = false;
      window.open(response.data);
    });
  }
}
