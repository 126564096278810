import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  private loginDetails = new BehaviorSubject<any>(null);
  private isLoggined = new BehaviorSubject<any>(null);

  sendLoginData(loginData: any) {
    console.log('SendLogin Data triggered');
    this.loginDetails.next(loginData);
    this.getLoginData();
  }

  getLoginData(): Observable<any> {
    console.log('GetLogin Data triggered');
    return this.loginDetails.asObservable();
  }

  sendisLoggined(isLoggined: any) {
    console.log('sendisLoggined Data triggered');
    this.isLoggined.next(isLoggined);
  }

  getisLoggined(): Observable<any> {
    console.log('getisLoggined Data triggered');
    return this.isLoggined.asObservable();
  }

}
