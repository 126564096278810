import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';
import { WebService } from './../../provider/web.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SubjectService } from './../../provider/subject.service';
import { trigger, transition, animate, style } from "@angular/animations";



@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.scss'],
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("200ms ease-in", style({ transform: "translateY(0%)" }))
      ]),
      transition(":leave", [
        animate("200ms ease-out", style({ transform: "translateY(100%)" }))
      ])
    ])
  ],
})
export class SearchresultComponent implements OnInit {
  isShow = false;
    bookFare: any;
    maxseatflag :boolean;
    seatData: any;
    seatcount :any;
  searchData: any;
  imageSrc:any;
  imageSrcS:any;
  editable: boolean;
  searchForm: FormGroup;
  searchSubmit: boolean;
  funcName: any;
  results: any[];
  sourceFlag: boolean;
  destFlag: boolean;
  main_error: boolean;
  desfuncName: any;
  destResults: any[];
  today: any;
  bus_result: any;
  filter_type: any;
  user_filter: any;
  service_status: boolean;
  selected_seats: any[];
  booking_selected: any[];
  book_type: any;
  boardingPoints: any;
  isImageshow = false;
  droppingPoints: any;
  loginDetails: any;
  source_state: boolean;
  dest_state: boolean;
  loading: boolean;
  seatLoad: boolean;
  customAmount: any[];
  boardandDropDetails = {brdPoint: '', brdTime: '', dropPoint: '', dropTime: '' };
  schedduleCode: any;
  loginalert: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public service: WebService,
    private _localeService: BsLocaleService,
    public subjectService: SubjectService
    ) {
    this._localeService.use('engb');
    this.editable = false;
    this.sourceFlag = false;
    this.destFlag = false;
    this.source_state = false;
    this.dest_state = false;
    this.loading = false;
    this.seatLoad = false;

    this.searchForm = this.formBuilder.group({
      fromStationId: ['', Validators.compose([Validators.required])],
      toStationId: ['', Validators.compose([Validators.required])],
      travelDate: ['', Validators.compose([Validators.required])],
      returnDate: [''],
      sourceName: ['', Validators.compose([Validators.required])],
      destinationName: ['', Validators.compose([Validators.required])],
    });

    this.searchSubmit = false;
    this.main_error = false;
    this.service_status = false;
    this.today = new Date();
    this.filter_type = {'travels': [], 'pick_point': [], 'drop_point': [],'bus_type':[]};
    this.user_filter = {'travels': [], 'pick_point': [], 'drop_point': [], 'time': [], 'rate': [], 'type': []};
    this.book_type = {
        'operatorCode': '',
        'scheduleCode': '',
        'travelDate': '',
        'fromStationCode': '',
        'toStationCode': '',
        'boardingPointCode': '',
        'droppingPointCode': '',
        'email': '',
        'mobile': '',
        'passenger': [],
        'basic': {'from': '', to: '', start_time: '', arrival: '', 'travels': '', 'board': '', 'drop': ''}
    };
    this.selected_seats = new Array();
    this.booking_selected = new Array();
    this.customAmount = [];
    this.seatcount = 0;
    this.maxseatflag = false;
    this.getCurrency();
    this.loginalert = false;
  }


  @Input() rating: number;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;
  ngOnInit() {
    this.inputName = this.itemId + '_rating';
    const data = decodeURIComponent(this.route.snapshot.params['id']);
    console.log('data', data);
    this.searchData = JSON.parse(data);
    const travelDate = this.searchData.travelDate.split('T');
    this.searchData.travelDate = travelDate[0];
    console.log(this.searchData);
    let mydate = new Date(this.searchData.travelDate);
    console.log(mydate);
    this.searchForm.controls['sourceName'].patchValue(this.searchData.sourceName);
    this.searchForm.controls['fromStationId'].patchValue(this.searchData.fromStationId);
    this.searchForm.controls['destinationName'].patchValue(this.searchData.destinationName);
    this.searchForm.controls['toStationId'].patchValue(this.searchData.toStationId);
    this.searchForm.controls['travelDate'].patchValue(mydate);

    // if (this.searchData.returnDate !== null && this.searchData.returnDate !== '') {
    //     const returnDate = this.searchData.returnDate.split('T');
    //     this.searchData.returnDate = returnDate[0];
    //     mydate = new Date(this.searchData.returnDate);
    //     this.searchForm.controls['returnDate'].patchValue(mydate);
    // }

    this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));

    this.subjectService.getLoginData().subscribe(loginData => {
        this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));
    });
    this.onChange();
    this.get_search_bus();

  }
  closeLoginAlert() {
    this.loginalert = false;
  }

  onChange() {
    const funcNamed = 'source_location/';
    this.searchForm.get('sourceName').valueChanges.pipe(debounceTime(500), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(funcNamed + query.replace(/[^a-zA-Z ]/g, '')))).subscribe(response => {
      if (response.status === 'success' && this.source_state === true) {
        this.results = response.data;
        console.log(this.results);
        if (this.results.length > 0 ) {
          this.sourceFlag = true;
        } else {
          this.sourceFlag = false;
        }
        console.log(this.sourceFlag);
      } else {
        this.results = [];
        this.sourceFlag = false;
      }
    });

    this.desfuncName = 'destination_location/';
    this.searchForm.get('destinationName').valueChanges.pipe(debounceTime(500), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(this.desfuncName + query.replace(/[^a-zA-Z ]/g, '') + '/' + this.searchForm.controls['fromStationId'].value)))
    .subscribe(response => {
      if (response.status === 'success' && this.dest_state === true) {
        this.destResults = response.data;
        console.log(this.results);
        if (this.destResults.length > 0 ) {
          this.destFlag = true;
        } else {
          this.destFlag = false;
        }
        console.log(this.sourceFlag);
      } else {
        this.destResults = [];
        this.destFlag = false;
      }
    });
  }

  istoggle() {

    this.isShow = !this.isShow;
  }
  isImage(Image: string,ImageS: string) {
    this.imageSrc = Image;
    this.imageSrcS = ImageS;
    this.isShow = !this.isShow;
    
  }

  plusNext() {
    this.isImageshow = true;
  }
  plusprev() {
    this.isImageshow = false;
  }
  
  onClick(rating: number): void {
      console.log(rating);
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }

  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  modify() {
    this.editable = !this.editable;
  }

  bus_search() {
    this.main_error = false;
    console.log(this.searchForm.value);
    this.searchSubmit = true;
    const response = this.searchForm.value;
    const This = this;
    console.log(this.searchForm.value);
    if (this.searchForm.valid) {
      this.searchData = this.searchForm.value;
      this.searchData.travelDate = this.formatDate(this.searchData.travelDate);
     // this.searchData.returnDate = this.searchData.returnDate !== '' ? this.formatDate(this.searchData.returnDate) : '';
      const searchObj = encodeURIComponent(JSON.stringify(this.searchForm.value));
      console.log(this.searchData);
      const url = 'searchresult/' + searchObj;
      this.router.navigateByUrl(url);
      this.editable = false;
    } else {
      const data = this.searchForm.value;
      if (data.sourceName !== '' && data.destinationName !== '' && data.travelDate !== '') {
        if (data.fromStationId === '' || data.toStationId === '') {
          this.main_error = true;
        }
      }
    }
    this.get_search_bus();
  }

  select_source(data: any) {
    this.source_state = false;
    this.sourceFlag = false;
    this.searchForm.controls['sourceName'].patchValue(data.stationName);
    this.searchForm.controls['fromStationId'].patchValue(data.stationCode);
  }

  select_dest(data: any) {
    this.destFlag = false;
    this.dest_state = false;
    this.searchForm.controls['destinationName'].patchValue(data.stationName);
    this.searchForm.controls['toStationId'].patchValue(data.stationCode);
  }

  switch_loc() {
    const inter_source =   this.searchForm.controls['sourceName'].value;
    const inter_source_id = this.searchForm.controls['fromStationId'].value;
    const inter_destination =   this.searchForm.controls['destinationName'].value;
    const inter_destination_id = this.searchForm.controls['toStationId'].value;

    this.searchForm.controls['sourceName'].patchValue(inter_destination);
    this.searchForm.controls['fromStationId'].patchValue(inter_destination_id);
    this.searchForm.controls['destinationName'].patchValue(inter_source);
    this.searchForm.controls['toStationId'].patchValue(inter_source_id);
    console.log(this.searchForm.value);
    this.searchData = this.searchForm.value;
  }

  change_date(type: string) {
    const curr_date = new Date().getTime();
    const set_date = new Date(this.searchData.travelDate);
    let choose_date;
    if (type === 'prev') {
      choose_date = set_date.setDate(set_date.getDate() - 1);
    } else {
      choose_date = set_date.setDate(set_date.getDate() + 1);
    }

    if (curr_date <= choose_date) {
      const d = new Date(choose_date).toISOString();
      const datechoose = d.split('T');
      console.log(datechoose[0]);
      this.searchData.travelDate = datechoose[0];
      const mydate = new Date(this.searchData.travelDate);
      this.searchForm.controls['travelDate'].patchValue(mydate);
    }
    this.get_search_bus();
  }

  get_search_bus() {
      this.loading = true;
      this.service_status = false;
      console.log(this.searchData);

    this.funcName = 'customPrice';
    this.loading = true;
    this.service.post_data(this.funcName, this.searchData).subscribe(response => {
        // this.loading = false;
        if ( response.status === 'success') {
            this.customAmount = response.data;
        } else {
            this.customAmount = [];
        }
    }, (error) => {
        console.log(error);
    });


    // tslint:disable-next-line:max-line-length
    const passData = {
      fromStation : this.searchData.fromStationId,
      toStation : this.searchData.toStationId,
      travelDate : this.searchData.travelDate
   };
    this.service.post_data('search', passData).subscribe(response => {
      console.log(response);
        this.loading = false;
        this.bus_result = response;
        this.service_status = true;
        console.log(this.bus_result);
        if (this.bus_result.code === 1) {
             if (this.bus_result.searchResult instanceof Array !== true) {
                this.bus_result.searchResult = new Array(this.bus_result.searchResult);
            }
            console.log('here',this.bus_result);
            this.filter_result(this.bus_result.searchResult);
        } else {
            this.bus_result = {'searchResult': []};
        }
    }, (error) => {
      console.log(error);
    });
   // console.log(this.bus_result);
  }
 returnTicketBooking() {
   console.log('Return ticket Data',this.searchData);
   const passData = {
    fromStation : this.searchData.toStationId,
    toStation : this.searchData.fromStationId,
    travelDate : this.searchData.returnDate
   };
   this.service.post_data('search', passData).subscribe(response => {
    console.log(response);
      this.loading = false;
      this.bus_result = response;
      this.service_status = true;
      console.log(this.bus_result);
      if (this.bus_result.code === 1) {
           if (this.bus_result.searchResult instanceof Array !== true) {
              this.bus_result.searchResult = new Array(this.bus_result.searchResult);
          }
          console.log(this.bus_result);
          this.filter_result(this.bus_result.searchResult);
      } else {
          this.bus_result = {'searchResult': []};
      }
  }, (error) => {
    console.log(error);
  });
}

  filter_result(result) {
  
    result.forEach(element => {
      this.filter_type.travels.push({'id': element.operatorCode, 'name': element.operatorName});
      this.filter_type.bus_type.push({'id': element.busType, 'name': element.busType});
      if (element.boardingPointDetails instanceof Array) {
        // element.boardingPointDetails.forEach(item => {
        //   this.filter_type.pick_point.push({'id': item.code, 'name': item.name});
        // });
      } else {
        // if (element.boardingPointDetails !== undefined) {
        //     this.filter_type.pick_point.push({'id': element.boardingPointDetails.code, 'name': element.boardingPointDetails.name});
        // }
      }

      if (element.droppingPointDetails instanceof Array) {
        // element.droppingPointDetails.forEach(item => {
        //     this.filter_type.drop_point.push({'id': item.code, 'name': item.name});
        // });
      } else {
          // if (element.droppingPointDetails !== undefined) {
          //   this.filter_type.drop_point.push({'id': element.droppingPointDetails.code, 'name': element.droppingPointDetails.name});
          // }
      }
      // element.rate = this.check_rating(element.ratings, 10);
      // tslint:disable-next-line:max-line-length
      element.seat_layout = {'availableSeats': '', 'boardingPoints':  '', 'busMapStructure': '', 'code': '', 'columnsList': '', 'layerCount': 0, 'message': '', 'rowsList': ''};
    });
    this.filter_type.pick_point = this.removeDuplicates(this.filter_type.pick_point, 'name');
    this.filter_type.drop_point = this.removeDuplicates(this.filter_type.drop_point, 'name');
    this.filter_type.travels = this.removeDuplicates(this.filter_type.travels, 'id');
    this.filter_type.bus_type = this.removeDuplicates(this.filter_type.bus_type, 'id');
    // console.log(this.filter_type);
  }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    choose_type(type: string, value, event) {
        let index;
        if (event.target.checked === true) {
            this.user_filter[type].push(value);
        } else {
            index = this.user_filter[type].indexOf(value);
            this.user_filter[type].splice(index, 1);
        }
        console.log(this.user_filter);
    }

    // check_rating(rating, rate) {
    //     let avg_count = 0;
    //     let bus_rate = 0;
    //     let punc_rate = 0;
    //     let staff_rate = 0;
    //     let total_rate = 0;
    //     if (rating.busQuality !== 'UNRATED') {
    //         avg_count += 1;
    //         bus_rate = this.rate_value(rating.busQuality);
    //     }

    //     if (rating.punctuality !== 'UNRATED') {
    //         avg_count += 1;
    //         punc_rate = this.rate_value(rating.punctuality);
    //     }

    //     if (rating.punctuality !== 'UNRATED') {
    //         avg_count += 1;
    //         staff_rate = this.rate_value(rating.staffBehavior);
    //     }

    //     total_rate = bus_rate + punc_rate + staff_rate;
    //     const avg_rate = Math.round(total_rate / avg_count);
    //     const avg_star = 5 / 3;
    //     return Math.round(avg_rate * avg_star);
    // }

    rate_value(type: string) {
        switch (type) {
            case 'POOR':
                return 1;
            break;
            case 'AVERAGE':
                return 2;
            break;
            case 'GOOD':
                return 3;
            break;
            default:
                return 0;
        }
    }

    get_filter_journey(journey: string) {
        let journeyTime;
        journeyTime = journey.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [journey];
        journeyTime = journeyTime.slice (1);
        journeyTime.splice(3, 1);
        return journeyTime.join ('');
    }

    time_string(time_list: string) {
        let time_split: any;
        const date_list = time_list.split(' - ');
        const start_time = date_list[0];
        const end_time = date_list[1];
        time_split = start_time.split(':');
        const date2 = new Date();
        date2.setHours(time_split[0]);
        date2.setMinutes(time_split[1]);
        time_split = end_time.split(':');
        const date3 = new Date();
        date3.setHours(time_split[0]);
        date3.setMinutes(time_split[1]);
        return date2.getTime() + '-' + date3.getTime();
    }

    formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }
        return [year, month, day].join('-');
    }

    min_return () {
        const d = this.searchForm.get('travelDate').value;
        return d;
    }

    base_fare(map_array, count, scheduleCode: any) {
       // console.log(map_array, count, scheduleCode);
        if (count > 0) {
            let array = [];
            if (count === 2) {
                array = map_array.lower.concat(map_array.upper);
            } else {
                array = map_array.lower;
                console.log('Array',array);
            }
            const flags = [], output = [], l = array.length;
            let i;
            for (i = 0; i < l; i++) {
                if (flags[array[i].baseFare]) {
                    continue;
                } else {
                    flags[array[i].baseFare] = true;
                    if ( array[i].baseFare !== '0.0'  ) {
                       const fareAmt = this.checkPrice(array[i].baseFare, scheduleCode);
                       output.push('&#8377;' + fareAmt);
                    }
                }
            }
            return output.join('-');
        } else {
            return 0;
        }
    }

    get_seats(index: any, scheduleCode: any, operatorCode: any) {
      
      this.schedduleCode = null
      this.schedduleCode = scheduleCode;
        index = this.bus_result.searchResult.findIndex(items =>  items.scheduleCode === scheduleCode);
       // console.log('index1',index)
        this.seatLoad = true;
        this.booking_selected[0] = this.book_type;
        this.selected_seats[scheduleCode] = new Array;
        console.log(this.searchData);
        const operatorData = this.bus_result.searchResult[index];
        console.log(operatorData);
      // tslint:disable-next-line:max-line-length
      // const url = 'busmapV2?username=javatest&password=123456&travelDate=' + this.searchData.travelDate + '&fromStationCode=' + this.searchData.fromStationId + '&toStationCode=' + this.searchData.toStationId + '&scheduleCode=' + scheduleCode + '&operatorCode=' + operatorCode;
      // console.log(url);
      const passData = {
        'travelDate' : this.searchData.travelDate,
        'fromStationCode': this.searchData.fromStationId,
        'toStationCode' :  this.searchData.toStationId,
        'operatorCode' : operatorCode,
        'scheduleCode' : scheduleCode,
         'busregno'  : operatorData.busRegNo
      }
      this.service.post_data('busmap' , passData).subscribe(response => {
          this.seatLoad = false;
        console.log(response);
        if (response.code === 1) {
            this.bus_result.searchResult[index].seatErrMsg = undefined;
            this.bus_result.searchResult[index].seat_layout = response;
             this.bus_result.searchResult[index].seat_layout['boardingPoints'] = this.service.ObjArray(response.boardingPoints);
             this.bus_result.searchResult[index].seat_layout['droppingPoints'] = this.service.ObjArray(response.droppingPoints);
             console.log('Bus result',this.bus_result.searchResult[0].seat_layout['layout'])
             this.bus_result.searchResult[index].seat_layout['layout'] = this.seat_arrange(response);
        } else {
            this.bus_result.searchResult[this.schedduleCode].seatErrMsg  = '<small style="text-align:enter">' + response.errorDesc + '</small>';
        }
        console.log(this.bus_result);
      }, (error) => {
        console.log(error);
      });
    }

    get_seat_layer(count: number) {
        return 12 / count;
    }

    seat_arrange(response) {
        let lower_rows: any, lower_column: any;
        let upper_rows: any, upper_column: any;
        const lower_seats = [];
        let upper_seats_rows = [];
        const upper_seats = [];
        let lower_seats_rows = [];
        if (response.layerCount  === '2') {
            lower_rows = response.rowsList[0];
            lower_column = response.columnsList[0];

            upper_rows = response.rowsList[1];
            upper_column = response.columnsList[1];
            console.log(lower_rows,lower_column,upper_rows,upper_column)
            for ( let i = 1; i <= upper_rows; i++) {
                upper_seats_rows = [];
                for (let j = 1; j <= upper_column; j++) {
                    response.busMapStructure[1].upper.filter(seats => {
                        if (seats.row == i && seats.column == j) {
                            upper_seats_rows.push(seats);
                        }
                    });
                }
                upper_seats.push(upper_seats_rows);
            }

        } else {
            lower_rows = response.rowsList;
            lower_column = response.columnsList;
        }

        console.log(lower_rows, lower_column);
        for ( let i = 1; i <= lower_rows; i++) {
            lower_seats_rows = [];
            for (let j = 1; j <= lower_column; j++) {
                response.busMapStructure[0].lower.filter(seats => {
                    if (seats.row == i && seats.column == j) {
                        lower_seats_rows.push(seats);
                    }
                });
            }
            lower_seats.push(lower_seats_rows);
        }

        const numList = {'lower_rows': lower_rows, 'lower_column': lower_column, 'upper_rows': upper_rows, 'upper_column': upper_column };

        const seats_return = {'lower_seats' : lower_seats, 'upper_seats': upper_seats, 'numList': numList};
        return seats_return;

    }
    set_book(item: any, index: any, scheduleCode: any, event) {
     console.log('index',item);
      event.checked = false;
      const obj = this.selected_seats[index].findIndex(items =>  items.seatNumber === item.seatNumber);
      console.log(obj);
      if (obj === -1) {
         const item_price = this.checkPrice(item.baseFare, scheduleCode);
         item.baseFare = item_price;
          // const seat_price = this.checkPrice(item.seatFare, scheduleCode);
          // item.seatFare = seat_price;
          console.log('Item price', item);
          console.log('Item');
          if (this.selected_seats[index].length < 6) {
              this.selected_seats[index].push(item);
              this.seatcount =  this.selected_seats[index].length;
              this.maxseatflag= false;
              this.seatData = this.selected_seats[index];

          } else {
          event.preventDefault();
          this.maxseatflag= true;
         }
      } else {
          this.selected_seats[index].splice(obj, 1);
          console.log('Array length',this.selected_seats[index].length);
      }
  }

    check_checked(item: any, index: number, scheduleCode: any) {
        return false;
      console.log(this.selected_seats[index]);
        console.log(item);
        const obj = this.selected_seats[index].findIndex(items =>  items.seatNumber === item.seatNumber);
        if (obj === -1) {
            return false;
        } else {
            return true;
        }
    }

    check_exist(seatNumber: any, index: any) {
        const id = this.selected_seats[index].length + 1;
        
        const found = this.selected_seats[index].some(function (el: any) {
            return el.seatNumber === seatNumber;
        });
        if (found) { return true; }
    }

    book_details(type: string) {
       // tslint:disable-next-line:max-line-length
        if (this.selected_seats[this.schedduleCode] instanceof Array === true && typeof this.selected_seats[this.schedduleCode] !== 'undefined' && this.selected_seats[this.schedduleCode].length > 0) {
            if (type === 'seats') {
                return this.selected_seats[this.schedduleCode].map(e => e.seatNumber).join(', ');
            } else if (type === 'seat_fare') {
               // tslint:disable-next-line:radix
                const sum = this.selected_seats[this.schedduleCode].map(item => item.baseFare).reduce((prev, next) => parseInt(prev) + parseInt(next));
                return sum;
            } else {
                // tslint:disable-next-line:radix
                const oper = this.selected_seats[this.schedduleCode].map(item => item.baseFare).reduce((prev, next) => parseInt(prev) + parseInt(next));
                return oper;
            }
        }
        }

    booking_seat(index_new: number, type) {
      if(this.searchData.onwardbookingID){
         if(this.searchData.onwardPassengerCount != this.seatData.length)
         {
           alert("Seat Count shouuld be same in return ticket booking");
           return;
         }
      }
      console.log('pascount',this.seatData.length);
        const index = this.bus_result.searchResult.findIndex(items =>  items.scheduleCode === index_new)
        this.booking_selected[0].operatorCode =  this.bus_result.searchResult[index_new].operatorCode;
        this.booking_selected[0].scheduleCode =  this.bus_result.searchResult[index_new].scheduleCode;
        this.booking_selected[0].travelDate =  this.searchData.travelDate;
        this.booking_selected[0].returnDate = this.searchData.returnDate;
        this.booking_selected[0].fromStationCode =  this.searchData.fromStationId;
        this.booking_selected[0].toStationCode =  this.searchData.toStationId;
        this.booking_selected[0].passenger =  this.seatData;
        this.booking_selected[0].basic.from = this.searchData.sourceName;
        this.booking_selected[0].basic.to = this.searchData.destinationName;
        this.booking_selected[0].basic.start_time = this.bus_result.searchResult[index_new].departureTime;
        this.booking_selected[0].basic.arrival = this.bus_result.searchResult[index_new].arrivalTime;
        this.booking_selected[0].travels = this.bus_result.searchResult[index_new].operatorName;
        this.booking_selected[0].busType = this.bus_result.searchResult[index_new].busType;
        this.booking_selected[0].bookingID = this.searchData.onwardbookingID;
        if (this.loginDetails) {
            // tslint:disable-next-line:max-line-length
            this.booking_selected[0].email = this.loginDetails.username !== undefined ? this.loginDetails.username : this.loginDetails.email_id;
            this.booking_selected[0].mobile = this.loginDetails.mob !== undefined ? this.loginDetails.mob : this.loginDetails.phone;
            const url = 'booking/' +  encodeURIComponent(JSON.stringify(this.booking_selected[0]));
            this.router.navigateByUrl(url);
        } else {
          this.loginalert = true;
            this.subjectService.sendisLoggined('not_loggedIn');
        }
        console.log(this.booking_selected[0]);
        // this.booking_selected[index].
    }
    testfun()
    {
        console.log('Flag' , this.maxseatflag);
        if (this.maxseatflag = true) {
            return false;
        }
    }
    check_booking(index: any) {
  
        // tslint:disable-next-line:max-line-length
        if (this.selected_seats[index] instanceof Array === true && typeof this.selected_seats[index] !== 'undefined' && this.selected_seats[index].length > 0) {
            if (this.booking_selected[0].boardingPointCode !== '') {
                // if (this.bus_result.searchResult[index].seat_layout['droppingPoints']) {
                    // if (this.booking_selected[index].droppingPointCode !== '') {
                    //     return false;
                    //     console.log('false')
                    // } else {
                    //     console.log('Else')
                    //     return true;
                    // }
                // } else {
                //     console.log('false')
                //     return false;
                // }
            } else {
                
                return true;
            }
        } else {
            // console.log('true')
            return true;
        }
    }

    choose_location(type: string, index, code: any) {
        console.log('code', code , 'type', type, 'index', index)
        const select_loc = code.selectedOptions[0].innerHTML;
        console.log('Booking Selected', this.booking_selected);
        if (type === 'board') {
            this.booking_selected[0].boardingPointCode = code.value;
            this.booking_selected[0].basic.board = select_loc;
        } else {
            this.booking_selected[0].droppingPointCode = code.value;
            this.booking_selected[0].basic.drop = select_loc;
        }
    }

    value_change(type: string) {
        console.log(this.source_state);
        if (type === 'source') {
          this.source_state = true;
        } else {
          this.dest_state = true;
        }
    }

    initCalled() {
        this.sourceFlag = false;
        this.destFlag = false;
        console.log(this.source_state);
    }

    getWidth(col: number){
       const col_width =  (100 / col);
       return col_width + '%';
    }

    getIds(type: string) {
        if (type === 'Sleeper') {
            return 'sleeper_button';
        } else if (type === 'Seater') {
            return 'seat_button';
        } else {
            return '';
        }
    }

    checkPrice(amount: any, scheduleCode: any) {
        const This = this;
        let amtArray = amount.toString().split(',');
        const rate = this.customAmount.forEach(function (el) {
            if (el.scheduleId === scheduleCode) {
                amtArray = amtArray.map(function(val) {
                    console.log(val,el.amount);
                    const amt =  parseFloat(val) + parseFloat(el.amount);
                    return amt;
                });
            }
        });
        if (This.loginDetails) {
            amtArray = amtArray.map(function(val) {
                if (This.loginDetails.type === 'agent') {
                    const discount = ((val * This.loginDetails.discount) / 100);
                    console.log(discount);
                    return (val - discount).toFixed(2);
                } else {
                    return val;
                }
            });
        }
        return amtArray.join(', ');
    }
    getCurrency() {
      const country = JSON.parse(this.service.getLocalStorageItem('currency'));
      return country.symbol;
      console.log(country);
    }
}
