export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyA8lFr3aSIuqpMbIRvPlFw06sBA7gD5gcQ',
    authDomain: 'mogobus-ab3d8.firebaseapp.com',
    databaseURL: 'https://mogobus-ab3d8.firebaseio.com',
    projectId: 'mogobus-ab3d8',
    storageBucket: 'mogobus-ab3d8.appspot.com',
    messagingSenderId: '1:558748169995:web:cbd8e60e895fb97060140e'
  }
};
