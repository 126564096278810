import { Component, OnInit, ViewChild, TemplateRef,VERSION } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WebService } from './../../provider/web.service';
import {
  trigger,
  transition,
  state,
  animate,
  style
} from '@angular/animations';
declare const $: any;
declare const FlutterwaveCheckout: any;
declare var Razorpay: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  animations: [
    trigger('slideInNew', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class BookingComponent implements OnInit {
  bookData: any;
  totalAmount: number;
  public passengerForm: FormGroup;
  bookSubmit: boolean;
  mobnumPattern = '^((\\+?)|0)?[0-9]{0,20}$';
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  modalRef: BsModalRef;
  book_result: any;
  bookFare: any;
  loginDetails: any;
  bookingId: any;
  @ViewChild('payment') private login: TemplateRef<HTMLElement>;
  promocode: any;
  funcName: any;
  loading: boolean;
  promoMsg: boolean;
  booked_error: any;
  currencyCode: any;
  bookLoad: boolean;
  promoLoad: boolean;
  walletData: any;
  gstResponse: any;
  userData: any;
  isShow = false;
  promoAmount: any;
  promoFlag: boolean;
  countryCode:any;
  choose_type:any;
  PhoneNolength:any;
  customer_phone:any;
  phone_error:any;
  email_error :any;
  cus_email:any;
  singleBookData:any;
  SingleTripFare:any;
  final_amount:any;
  a:any;
  b:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public vs: ValidationService,
    private bsmodalservice: BsModalService,
    private service: WebService
  ) {
    this.countryCode = JSON.parse(this.service.getLocalStorageItem('userCountry'));
    this.userData = JSON.parse(this.service.getLocalStorageItem('userData'));
    this.bookSubmit = false;
    this.loading = false;
    this.promoLoad = false;
    this.funcName = 'getGst';
    this.promocode = '';
    this.promoMsg = false;
    this.promoAmount = 0;
    this.promoFlag = false;
    
    this.service.post_data(this.funcName, null).subscribe(
      responseData => {
        if (responseData.status === 'success') {
          console.log(responseData.data);
          this.gstResponse = responseData.data;
          this.bookFare = this.fareCharge(this.bookData.passenger);
        } else {
          console.log('failed');
        }
      },
      error => { }
    );
  }
  refId = '';
  ngOnInit() {
  
  this.choose_type= "email";
  this.phone_error ="";
  this.email_error ="";
    this.generateRef();
    $.getScript( 'https://checkout.flutterwave.com/v3.js', function( data, textStatus, jqxhr ) {
    console.log( data ); // Data returned
    console.log( textStatus ); // Success
    console.log( jqxhr.status ); // 200
    console.log( "Load was performed." );
    // data.FlutterwaveCheckout({}); // ???
  });

    //test
    // this.bookData = JSON.parse(this.route.snapshot.params.get('data'));
    // console.log(this.bookData);
    const bookData = decodeURIComponent(this.route.snapshot.params['data']);
    this.bookData = JSON.parse(bookData);
    console.log('BookData', this.bookData)
    if(this.bookData.bookingID){
      this.service.post_data("getSingleBookdetails", this.bookData).subscribe(
        responseData => {
          if (responseData.status === 'success') {
            this.singleBookData = responseData.data;
            this.SingleTripFare =this.singleBookData.amount;
            
          } else {
            console.log('failed');
          }
        },
        error => { }
      );

    }

    this.totalAmount = this.total_amount(this.bookData);
    console.log(this.bookData);
    this.PhoneNolength = (this.countryCode =="+237" ?9:10);
    this.passengerForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      phone: [
        '',
        // Validators.compose([
        //   Validators.required,
        //   Validators.minLength(this.PhoneNolength),
        //   Validators.maxLength(15),
        //   Validators.pattern(this.mobnumPattern)
        // ])
      ],
      // tslint:disable-next-line:max-line-length
      email: [
        '',
        // Validators.compose([
        //   Validators.required,
        //   Validators.maxLength(50),
        //   Validators.pattern(this.emailPattern)
        // ])
      ],
      passenger: this.fb.array([])
    });
    this.patchValues();
    this.loginDetails = JSON.parse(
      this.service.getLocalStorageItem('userData')
    );

    if (this.loginDetails.type === 'agent') {
      const postData = { userId: this.loginDetails.id };
      this.funcName = 'walletAmount';
      this.service.post_data(this.funcName, postData).subscribe(
        responseData => {
          if (responseData.status === 'success') {
            this.walletData = responseData.data;
          } else {
            console.log('failed');
          }
        },
        error => { }
      );
    }
    
  }
 
 
  generateRef() {
    this.refId = `PAYVtestuser1-${Date.now()}`;
  }
  paymentCancel(e) {
    console.log('Why canceled?',e);
    this.generateRef(); // Generate Ref for next transcation
  }
  paymentDone(res) {
    this.generateRef(); // Generate Ref for next transcation
    console.log('My response', res);
  }
  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  isShowCancelPopup() {
    this.isShow = !this.isShow;
  }

  total_amount(data: any) {
    // tslint:disable-next-line:radix
    const total_fare = this.bookData.passenger
      .map(item => item.baseFare)
      .reduce((prev, next) => parseInt(prev) + parseInt(next));
    return total_fare;
  }

  patchValues() {
    const passengerCtrl = <FormArray>this.passengerForm.controls['passenger'];
    const passengerCount = this.bookData.passenger.length;
    let passenger_details;
    for (let i = 0; i < passengerCount; i++) {
      const gender = this.bookData.passenger[i].seatStatus === 'F' ? 'Female' : 'Male';
      passenger_details = {
        seatNumber: this.bookData.passenger[i].seatNumber,
        name: [null, Validators.compose([Validators.required])],
        age: [
          null,
          Validators.compose([
            Validators.required,
            Validators.min(3),
            Validators.max(60)
          ])
        ],
        gender: ['', Validators.compose([Validators.required])]
      };
      passengerCtrl.push(this.fb.group(passenger_details));
      
    }
  }
  booking(model: any, template: TemplateRef<any>) { 
 
    this.bookSubmit = true;
    if (this.passengerForm.valid && this.phone_error == "" && this.email_error == "") {
      this.isShow = true;
      console.log('if')
    }else{
      console.log('else')
      console.log(this.phone_error)
      console.log(this.choose_type)
      console.log(this.email_error )
      console.log(this.passengerForm.value);
    }
  }
  booking_return(model: any, template: TemplateRef<any>){
    this.bookSubmit = true;
    this.isShow = true;
    this.final_amount = parseFloat(this.bookFare.total)+parseFloat(this.singleBookData.amount);
    
  }
  return_temp_booking(){
   
   
  
    this.isShow = false;
    this.bookSubmit = true;
    const This = this;
    
    console.log(this.passengerForm.value);
 
     
      if (this.loginDetails.type === 'agent') {
        if ( this.walletData.amount < this.bookFare.total) {
          this.booked_error = '<small class="text-danger">Insufficent Balance. Please recharge your account</smal>';
          return false;
        }
      }
     
      this.bookLoad = true;
      const pass_data = this.passengerForm.value;
      this.bookData.droppingPointCode =
        this.bookData.droppingPointCode !== ''
          ? this.bookData.droppingPointCode
          : '';
      this.bookData.email = pass_data.email;
      this.bookData.mobile = pass_data.phone;
      this.bookData.promoId =this.bookData.promoId !== ''? this.bookData.promoId: 0;
      const postData = {
         operatorCode: this.bookData.operatorCode,
         scheduleCode: this.bookData.scheduleCode,
         travelDate: this.bookData.travelDate,
         fromStationCode: this.bookData.fromStationCode,
         toStationCode: this.bookData.toStationCode,
         boardingPointCode: this.bookData.boardingPointCode,
         droppingPointCode:  this.bookData.droppingPointCode,
         email: pass_data.email,
         mobile: pass_data.phone,
         passenger: pass_data.passenger,
         amount: this.bookFare.total,
         fare: this.bookFare.sum,
         charge: 0,
         tax: 0,
         gst: 0,
         promoId: this.bookData.promoId,
         discount: this.bookFare.discount,
        type: this.userData.type,
        userId: this.userData.id,
        "book_type": "1",
        "country_code": this.countryCode,
        "choose_type":this.choose_type,
        "single_book_id":this.bookData.bookingID
     
      };
      console.log('Return Ticket Date' , This.bookData.returnDate);
   
      this.service.post_data('booking', postData).subscribe(
        response => {
          if (response.status === 'success') {
             
              if (this.loginDetails.type === 'agent') {
                this.bookFare.payment = 'wallet';
              }
         
            const This = this;
            setTimeout(function () {
              This.bookLoad = false;
         
              var temp_book_id = response.data;
                
            //       if(This.bookData.bookingID) { alert("if")
            //       console.log('if',This.bookData.bookingID)
            //       var temp_book_id=This.bookData.bookingID;
            //      }else{alert("else")
            //       var temp_book_id = response.data;
            //      }
            //      console.log('out',temp_book_id)
                
            // return;
                if(This.bookFare.payment != 'wallet'){
                
                  This.flutterWavePayment(temp_book_id,This.final_amount);
                }else{
                  var transId=null;
                  This.ConfirmBooking(response.data,transId=null);
                }
              
            
               
            //  
            }, 1000);
          } else {
            this.booked_error =
              '<small class="text-danger">' +
              this.book_result.errorDesc +
              '</smal>';
            setTimeout(function () {
              This.booked_error = '';
            }, 3000);
          }
          console.log(this.book_result);
        },
        error => {
          console.log(error);
        }
      );
   

  }
 
    tempbooking(model: any, template: TemplateRef<any>) {
   
    
      
      if(this.bookData.bookingID){ 
        this.return_temp_booking();
        return;
      }

   
    this.isShow = false;
    this.bookSubmit = true;
    const This = this;
    console.log(this.passengerForm.value);
    if (this.passengerForm.valid ) {
     
      if (this.loginDetails.type === 'agent') {
        if ( this.walletData.amount < this.bookFare.total) {
          this.booked_error = '<small class="text-danger">Insufficent Balance. Please recharge your account</smal>';
          return false;
        }
      }
     
      this.bookLoad = true;
      const pass_data = this.passengerForm.value;
      this.bookData.droppingPointCode =
        this.bookData.droppingPointCode !== ''
          ? this.bookData.droppingPointCode
          : '';
      this.bookData.email = pass_data.email;
      this.bookData.mobile = pass_data.phone;
      this.bookData.promoId =
      this.bookData.promoId !== ''
        ? this.bookData.promoId
        : 0;
      const postData = {
         operatorCode: this.bookData.operatorCode,
         scheduleCode: this.bookData.scheduleCode,
         travelDate: this.bookData.travelDate,
         fromStationCode: this.bookData.fromStationCode,
         toStationCode: this.bookData.toStationCode,
         boardingPointCode: this.bookData.boardingPointCode,
        droppingPointCode:  this.bookData.droppingPointCode,
         email: pass_data.email,
         mobile: pass_data.phone,
         passenger: pass_data.passenger,
         amount: this.bookFare.total,
         fare: this.bookFare.sum,
         charge: 0,
         tax: 0,
         gst: 0,
         promoId: this.bookData.promoId,
         discount: this.bookFare.discount,
        type: this.userData.type,
        userId: this.userData.id,
        "book_type": "1",
        "country_code": this.countryCode,
        "choose_type":this.choose_type,
       // "transId":this.bookData.transId
        
          // "type": "user",
          // "userId": "191",
          // "operatorCode": "1",
          // "scheduleCode": "SCH001",
          // "travelDate": "2020-07-25",
          // "fromStationCode": "2",
          // "toStationCode": "4",
          // "boardingPointCode": "test place 1",
          // "droppingPointCode": "test place 2",
          // "email": "test1@gmail.com",
          // "country_code": "+91",
          // "mobile": "9876543210",
          // "passenger": [
          //     {
          //         "seatNumber": "l2",
          //         "name": "Test1",
          //         "age": 25,
          //         "gender": "Female"
          //     }
          // ],
          // "amount": "108.78",
          // "fare": "1000",
          // "charge": "8.78",
          // "tax": "0",
          // "gst": "0",
          // "discount": "50",
          // "promoId": "1",
          // "book_type": "2"
      
        
  
      };
      console.log('Return Ticket Date' , This.bookData.returnDate);
      // tslint:disable-next-line:max-line-length
      this.service.post_data('booking', postData).subscribe(
        response => {
          if (response.status === 'success') {
              // this.bookingId = this.book_result.data;
              // this.bookFare = this.fareCharge(this.bookData.passenger);
              if (this.loginDetails.type === 'agent') {
                this.bookFare.payment = 'wallet';
              }
             // model: any, template: TemplateRef<any>
              // this.modalRef = this.bsmodalservice.show(template, {
              //   backdrop: "static",
              // });
            // }
            const This = this;
            setTimeout(function () {
              This.bookLoad = false;
              if(This.bookData.returnDate) {
               
                const returnPassData = {
                  destinationName: This.bookData.basic.to,
                  fromStationId: This.bookData.toStationCode,
                  travelDate: This.bookData.returnDate,
                  sourceName: This.bookData.basic.from,
                  toStationId: This.bookData.fromStationCode,
                  totalFare :This.bookFare.total,
                  onwardbookingID: response.data,
                  onwardPassengerCount:pass_data.passenger.length
                }
                This.goToPage('searchresult/'+ encodeURIComponent(JSON.stringify(returnPassData)))
              } else {
               
                var temp_book_id = response.data;
                  if(This.bookData.bookingID) {
                    temp_book_id=This.bookData.bookingID;
                }
                //console.log('bookFare',this.bookFare)
                if(This.bookFare.payment != 'wallet'){
                  This.flutterWavePayment(temp_book_id,This.bookFare.total);
                }else{
                  var transId=null;
                  This.ConfirmBooking(response.data,transId=null);
                }
              
              }
               
            //  
            }, 1000);
          } else {
            this.booked_error =
              '<small class="text-danger">' +
              this.book_result.errorDesc +
              '</smal>';
            setTimeout(function () {
              This.booked_error = '';
            }, 3000);
          }
          console.log(this.book_result);
        },
        error => {
          console.log(error);
        }
      );
    }
    // this.payment();
    
  }

  flutterWavePayment(bookingID,total){
   
    console.log('bookidfluitter',bookingID)
    const This =this;
    const pass_data = this.passengerForm.value;
    if(!(pass_data.email))
      {
        this.cus_email ="mogo@gmail.com";
 
      }  else{
        this.cus_email  =pass_data.email;
      } 
      const country = JSON.parse(this.service.getLocalStorageItem('currency'));
      this.currencyCode = country.currencyCode;
   //pass_data.passenger,
    // Trigger Rave inline popup here
    // flw.Checkout(); // ???
    console.log('Paying...');
    FlutterwaveCheckout({
       //public_key: "FLWPUBK-5ec3194fccdace2fda1245d6848d5cab-X",
      public_key: "FLWPUBK_TEST-31d61a13026483fc38f15f0e90232374-X",
      
      tx_ref: this.refId,
      // amount:this.bookFare.total,
      amount:parseFloat(total),
      currency: this.currencyCode,
      payment_options: "card",
      meta: {
        consumer_id: 23,
        consumer_mac: "92a3-912ba-1192a",
      },
      customer: {
        email: this.cus_email,
        phone_number: pass_data.phone,
        name: pass_data.passenger,
      },
 
      callback: function (data) {
        console.log('data',data)
       if(data.status=="successful"){
        
        
        This.ConfirmBooking(bookingID,data.transaction_id);

       }
        
    
      },
      customizations: {
        title: "Mogo Bus",
        description: "Payment for Mogo Bus Booking",
        //logo: "./../../../assets/imgs/logo.png",
        logi:"https://mogobus.co/src/assets/imgs/logo.png",
      }
    });
  }

  ConfirmBooking(id,transid) {
    console.log('ID', id);
    this.bookLoad = true;
    const passData = {
      bookingCode: id,
      transId:transid
    };
    this.service.post_data('confirmBooking', passData).subscribe(data => {
      console.log(data);
      this.bookLoad = false;
      if (data.status === 'success') {
       
        this.router.navigate(['/success/', data.data, this.bookFare.total], {
       
          replaceUrl: true
        });
       
      }
    });
  }
  close() {
    console.log('here')
    this.modalRef.hide();
  }



  fareCharge(passenger: any) {
    console.log('here')
    console.log(this.gstResponse);
    const gst = 0;
    // tslint:disable-next-line:radix
    const sum = passenger
      .map(item => item.baseFare)
      .reduce((prev, next) => parseInt(prev) + parseInt(next));
    // tslint:disable-next-line:radix
    // const ServiceTax = passenger.map(item => item.operatorServiceTax).reduce((prev, next) => parseInt(prev) + parseInt(next));
    // // tslint:disable-next-line:radix
    // const ServiceCharge = passenger.map(item => item.operatorServiceCharge).reduce((prev, next) => parseInt(prev) + parseInt(next));
    // tslint:disable-next-line:radix
    const total = parseFloat(sum);
    console.log('sum',  sum)
    // const index = this.bookData.busType.includes('Non');
    // if (index !== true) {
    //   gst = Math.round((total * this.gstResponse) / 100);
    //   total = Math.round(total + gst);
    // }
    // tslint:disable-next-line:max-line-length
    const res = {
      sum: sum,
      total: total,
      discount: 0,
      gst: 0,
      payment: 'online'
    };
    return res;
  }

  paymentType(type: any) {
    console.log(type);
    // this.bookFare.payment = type;
  }

  success(transId = null) {
    this.bookLoad = true;
    this.bookData.fare = this.bookFare;
    this.bookData.passenger = this.passengerForm.value.passenger;
    this.bookData.bookingId = this.bookingId;
    this.bookData.transId = transId;
    this.bookData.paymentType = this.bookFare.payment;
    this.bookData.promocode = this.promocode;
    this.bookData.userId = this.loginDetails.id;
    this.bookData.usertype = this.loginDetails.type;
    this.bookData.gst = this.gstResponse;
    this.bookData.choose_type = this.choose_type;
    console.log(this.bookData);
    const postData = { bookingCode: this.bookingId };

    const url = 'confirmbooking?username=javatest&password=123456';
    console.log(url);
    this.service
      .online_service(url, 'data=' + JSON.stringify(postData))
      .subscribe(
        response => {
          const confirm_result = response;
          console.log(confirm_result);
          if (confirm_result.message === 'Success') {
            const passenger = confirm_result.ticketDetails.passengerDetails;
            if (passenger instanceof Array !== true) {
              this.bookData.passenger[0].pnr = passenger.operatorPnr;
            } else {
              this.bookData.passenger.forEach(function (item: any, i: any) {
                item[i].pnr = passenger[i].operatorPnr;
              });
            }

            this.funcName = 'bookseat';
            this.service.post_data(this.funcName, this.bookData).subscribe(
              responseData => {
                this.bookLoad = false;
                if (responseData.status === 'success') {
                  this.router.navigate(
                    ['/success', this.bookingId, this.bookFare.sum],
                    { replaceUrl: true }
                  );
                } else {
                  console.log('failed');
                }
              },
              error => {
                this.bookLoad = false;
              }
            );
          } else {
            this.bookLoad = false;
          }
        },
        error => {
          this.bookLoad = false;
          console.log(error);
        }
      );
  }

  // check_promo(promovalue) {
  //   this.promoLoad = true;
  //   console.log(promovalue.value);
  //   const sendData = { userId: this.loginDetails.id, code: promovalue.value };
  //   const This = this;
  //   this.funcName = 'check_promo';
  //   this.service.post_data(this.funcName, sendData).subscribe(
  //     response => {
  //       this.promoLoad = false;
  //       if (response.status === 'success') {
  //         this.promoMsg =
  //           '<small class="text-info">' + response.message + '</smal>';
  //         let offer = (this.bookFare.sum * response.data.rate) / 100;
  //         offer =
  //           offer > response.data.max_limit ? response.data.max_limit : offer;
  //         this.bookFare.discount = offer;
  //         this.bookFare.total = this.bookFare.total - offer;
  //         this.promocode = response.data.id;
  //         console.log(response.data);
  //       } else {
  //         this.promoMsg =
  //           '<small class="text-danger">' + response.message + '</smal>';
  //         this.bookFare.discount = 0;
  //         this.promocode = 0;
  //         console.log('failed');
  //       }
  //       setTimeout(function () {
  //         This.promoMsg = undefined;
  //       }, 3000);
  //     },
  //     error => { }
  //   );
  // }

  getGender_state(index: any, type: any) {
    if (this.bookData.passenger[index].seatStatus === type) {
      return false;
    } else {
      return true;
    }
  }
  getCurrency() {
    const country = JSON.parse(this.service.getLocalStorageItem('currency'));
    return country.symbol;
  }
  closePopup() {
    this.isShow = false;
  }
  applyPromo() {
   
    this.promoMsg = false;
    if (this.promocode && this.promocode.length > 1) {
      this.bookLoad = true;
      const passData = {
        promo_id: this.promocode,
        operator_id : this.bookData.operatorCode
      };
      this.service.post_data('checkPromocode', passData).subscribe((data: any) => {
        this.bookLoad = false;
        console.log(data);

        if (data.code === 0) {
          if (data.data.type === 1) {
            let offer = ((this.bookFare.sum * data.data.rate) / 100);
            this.bookFare.discount = offer;
            this.bookFare.total = this.bookFare.total - offer;
            console.log('BookFare', this.bookFare.total);
            this.promoFlag = true;
            this.final_amount = this.final_amount- offer;
          }
          if (data.data.type === 2) {
            this.bookFare.discount = data.data.rate;
            this.bookFare.total = this.bookFare.total - this.bookFare.discount;
            this.promoFlag = true;
            this.final_amount = this.final_amount- this.bookFare.discount;
          }
          this.bookData.promoId = data.data.id;
          // this.
        } else {
          this.promoMsg = true;
          this.promoFlag = false;
        }
      });
    }
  }
  choose_option(option){
if(option == "email"){
  this.choose_type= "email";
  this.passengerForm.get('phone').reset();
  this.emailvalidate();
  this.phone_error = "";
}else{
  this.choose_type= "phone"; 
  this.phoneNovalidate();
  this.email_error = "";  
  this.passengerForm.get('email').reset();
  }
  }

  phoneNovalidate(){
    this.customer_phone =this.passengerForm.value.phone;
  if(this.customer_phone.length ==0 && this.choose_type== "phone"){
    this.phone_error ="Please Enter Mobile No"
  }else if (isNaN(this.customer_phone)== true && this.choose_type== "phone"){
    this.phone_error ="Invalid PhoneNo"
  }else if(this.customer_phone.length < this.PhoneNolength && this.choose_type== "phone"){
    this.phone_error ="Min Length"+this.PhoneNolength;
  }
  else if(this.customer_phone.length >15 && this.choose_type== "phone"){
    this.phone_error ="Max Length"+this.PhoneNolength;
  }else{
    this.phone_error = "";
  }
  }
  emailvalidate(){
    
    if(this.passengerForm.value.email.length ==0 && this.choose_type== "email"){
      this.email_error = "Please Enter Email";
    }
    else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.passengerForm.value.email))  && this.choose_type== "email")
    {
      this.email_error = "Invalid Email";
    }
    else{
      this.email_error = "";
    }
}
}
