import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { AuthService } from './provider/auth.service';
import { AuthGuard } from './provider/auth.guard';
import {MatSlideToggleModule, MatCheckboxModule} from '@angular/material'
import {
  HttpErrorHandler,
  HandleError
} from './provider/http-error-handler.service';
import { WebService } from './provider/web.service';
import { MessageService } from './provider/message.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
  
} from 'angular-6-social-login-v2';
import { ModalModule } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CancelsuccessComponent } from './cancelsuccess/cancelsuccess.component';
// import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ValidationService } from './provider/validation.service';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {HttpClient} from '@angular/common/http';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { initializeApp } from "firebase/app";
import { environment } from '../environments/environment';

const firebaseConfig = environment.firebase;
const app = initializeApp(firebaseConfig);

defineLocale('de', deLocale);

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('357715302071639')
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        '655498900655-bj42vp57m3ic76dvh9raomnma0dbrvqn.apps.googleusercontent.com'
      )
    }
  ]);
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    CancelsuccessComponent,
    AboutusComponent,
    AutocompleteOffDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    ModalModule.forRoot(),
    FlexLayoutModule,
    SocialLoginModule,
    MatSlideToggleModule, MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCk1_NXO6fDntAzKEl7sO-z6Ut5evHLsKQ'
    // }),
    AgmDirectionModule
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    AuthGuard,
    AuthService,
    HttpErrorHandler,
    WebService,
    MessageService,
    ValidationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
