import { Component } from "@angular/core";
import { WebService } from './provider/web.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "truebusapp";
  constructor(private translate: TranslateService,  public service: WebService,) {
    const lang =JSON.parse( this.service.getLocalStorageItem('userLang'));
    if(lang) {
      translate.setDefaultLang(lang);
    } else {
      translate.setDefaultLang('en');
    }
}
}
