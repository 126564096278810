import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebService } from './../../provider/web.service';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  bookingId: any;
  funcName: any;
  bookinfoData: any;
  amount: any;
  lang_choose:any;
  loading: boolean
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params['bookId'];
    this.amount = this.route.snapshot.params['amount'];
    this.getBookinfo(this.bookingId);
    console.log(this.bookingId);
  
  }

  getBookinfo(bookId) {
    this.loading = true;
    const url = 'getBookingDetails';
    const passData = {
      'bookingCode': bookId
    };
    this.service.post_data(url, passData).subscribe(response => {
      this.loading = false;
      this.bookinfoData = response;
    
   // window.location.reload();
      console.log('Book InfoData', this.bookinfoData.passengers);
      if (this.bookinfoData.passengers instanceof Array !== true) {
        this.bookinfoData.passengers = new Array(this.bookinfoData.passengers);
      }
    }, (error) => {
      console.log(error);
    });
  }

  navigate(type: string) {
    if (type === 'home') {
      this.router.navigate(['/index']);
    } else {
      this.router.navigate(['/account']);
    }
  }

  getSeats(passanger: any, type: string) {
    if (passanger instanceof Array !== true) {
      passanger = new Array(passanger);
    }

    const seatArray = new Array();
    passanger.forEach(item => {
      seatArray.push(item.seatNumber);
    });

    return seatArray.join(', ');

  }

  bookReplace(bookID: any) {
    const str = bookID;
    return str.replace(/^.{2}/g, 'TR');
  }
  downloadTciket(id) {
  
    this.loading = true;
    const lang =JSON.parse( this.service.getLocalStorageItem('userLang'));
    if(lang == 'fr'){
      this.lang_choose = 2
    } else{
      this.lang_choose = 1
    }
    
    
    const passData = {
      bookingCode: id,
      lang:this.lang_choose
    };
    console.log('pass',passData)
    this.service.post_data('pdf', passData).subscribe((response) => {
      this.loading = false;
      window.open(response.data);
    });
  }

}
