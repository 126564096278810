
import { Component, OnInit, HostListener, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormControl, FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ValidationService } from './../provider/validation.service';
import { WebService } from './../provider/web.service';
import { SubjectService } from './../provider/subject.service';
import { Login } from '../provider/declare.model';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, VkontakteLoginProvider} from 'angular-6-social-login-v2';
import {TranslateService} from '@ngx-translate/core';
import { trigger, transition, animate, style } from "@angular/animations";
import * as firebase from 'firebase';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var $;
declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',


    animations: [
      trigger("slideInOut", [
        transition(":enter", [
          style({ transform: "translateY(100%)" }),
          animate("200ms ease-in", style({ transform: "translateY(0%)" }))
        ]),
        transition(":leave", [
          animate("200ms ease-out", style({ transform: "translateY(100%)" }))
        ])
      ])
    ],

  styleUrls: ['./navbar.component.scss']
})


export class NavbarComponent implements OnInit {
currentNav: number;
isShow = false;
modalRef: BsModalRef;
phoneRecaptchaVerifier: firebase.auth.RecaptchaVerifier
signForm: FormGroup;
forgotForm: FormGroup;
loginForm: FormGroup;
agentForm: FormGroup;
printForm: FormGroup;
cancelForm: FormGroup;
@ViewChild('login') private login: TemplateRef<HTMLElement>;

mobnumPattern = '^((\\+?)|0)?[0-9]{0,20}$';
emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
emailandmobile = '^((\\+?)|0)?[0-9]{0,20}$|^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
passwordShow;
show = false;
email_id = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)]);
email_id_signup = new FormControl('', [Validators.required, Validators.minLength(8),Validators.maxLength(50), Validators.pattern(this.emailandmobile)]);
password = new FormControl('', [Validators.required, Validators.minLength(6)]);
name = new FormControl('', [Validators.required]);
// mob = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this.mobnumPattern)]);
term = new FormControl('', [Validators.required, Validators.pattern('true')]);
loading: boolean;
loginSubmit: boolean;
agentSubmit: boolean;
SignSubmit: boolean;
printSubmit: boolean;
funcName: string;
loginDetails: any;
loginResponse: any;
agentResponse: any;
signResponse: any;
socialResponse: any;
forgotResponse: any;
printResponse: any;
cancelResponse: any;
forgotSubmit: boolean;
cancelSubmit: any;
mobilenumber:any;
termsError:any;
currencyDetails:any;
  phonecode: any;
  country: any;
  country_code:any;
  user_or_agent:any;
  choose_lang:any
constructor(
  private router: Router,
  private route: ActivatedRoute,
  private bsmodalservice: BsModalService,
  private formBuilder: FormBuilder,
  public vs: ValidationService,
  public service: WebService,
  public subjectService: SubjectService,
  private socialAuthService: AuthService,
  private translate: TranslateService
  ) {
    this.loading = false;
    this.loginSubmit = false;
    this.SignSubmit = false;
    this.forgotSubmit = false;
    this.printSubmit = false;
    this.cancelSubmit = false;
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
      
    });
  }

  ngOnInit() {  

    let current_url = window.location.href;
    current_url.split("#");
    let cur_url_exp = current_url.split("#");
    this.user_or_agent = cur_url_exp[1];
    if(this.user_or_agent==='/contact')
    {
    this.currentNav=4;
    }
    else{
    this.currentNav=0;
    }
    console.log('ua', this.user_or_agent)
    this.choose_lang =JSON.parse( this.service.getLocalStorageItem('userLang'));
  if(this.choose_lang === null )
  {
    this.choose_lang='en'
  }
    this.passwordShow = 'password';
    let userId = '';
    this.country_code = '';
    this.currencyDetails =  JSON.parse(this.service.getLocalStorageItem('currency'));
    if(this.currencyDetails){
      this.country_code =  this.currencyDetails.code;
    }
    
  
    this.subjectService.getLoginData().subscribe(loginData => {
      this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));
      if (this.loginDetails) {
        userId = this.loginDetails.id;
      }
    });

    this.subjectService.getisLoggined().subscribe(isLoggined => {
      console.log(isLoggined);
      if (isLoggined != null) {
        if (isLoggined === 'not_loggedIn') {
          this.open(this.login, 'login');
        }
      }
   });

   this.printForm = this.formBuilder.group({
     'type': ['email'],
    'bookingID': ['', Validators.compose([Validators.required])],
    });

    this.cancelForm = this.formBuilder.group({
      'email': this.email_id,
      'bookingID': ['', Validators.compose([Validators.required])],
      'userId': [userId]
     });
     this.service.get_data('getCountries').subscribe((data:any)=> {
     
      if(data.status === 'success') {
        this.phonecode = data.data;
      } else {
        
      }
    })    
  

  }



  @HostListener('window:scroll', [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      console.log('You are 100px from the top to bottom');
    } else if (number > 500) {
        console.log('You are 500px from the top to bottom');
    }

    $(window).scroll(function() {
      const sticky = $('.navbar'),
        scroll = $(window).scrollTop();
        if (scroll >1) { sticky.addClass('scrolled_navbar'); } else { sticky.removeClass('scrolled_navbar'); }
    });
  }

   goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, {queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goToConatct(value) {
    this.currentNav=value;
    this.goToPage('/contact');
  }
  setLang(lang) {
    this.translate.setDefaultLang(lang);
    this.service.setLocalStorageItem('userLang', JSON.stringify(lang));
    this.choose_lang = lang;
  }

  form_initalize(type: string) {
    switch (type) {
      case 'login':
        this.loginForm = this.formBuilder.group({
          value: ['', Validators.required],
          password: this.password
        });
      break;
      case 'signup':
        this.currencyDetails =  JSON.parse(this.service.getLocalStorageItem('currency'));
        console.log('here',JSON.parse(this.service.getLocalStorageItem('currency')))
        if(this.currencyDetails){
          this.country_code =  this.currencyDetails.code;
        }
        
        this.signForm = this.formBuilder.group({
          username: this.email_id_signup,
          password: this.password,
          name: this.name,
          // mob: this.mob,
          // country_code: ['dfdfd', Validators.required],
          cpassword: ['', Validators.compose([Validators.required]), this.MatchPassword.bind(this)],
          terms: this.term,
        });
      break;
      case 'forgot':
        this.forgotForm = this.formBuilder.group({
          emailphoneNo: ['', Validators.compose([Validators.required, Validators.minLength(6),Validators.maxLength(50),Validators.pattern(this.emailandmobile)])],
        });
      break;
      case 'agent':
        this.agentForm = this.formBuilder.group({
          username: this.name,
          password: this.password
        });
      break;
    }

    if (this.forgotForm) {
      this.forgotForm.reset();
    } else if (this.signForm) {
      this.signForm.reset();
    } else if (this.agentForm) {
      this.agentForm.reset();
    } else {
      this.loginForm.reset();
    }
  }

  open(template: TemplateRef<any>, type: string) {
    console.log('calles');
    this.form_initalize(type);
    this.modalRef = this.bsmodalservice.show(template, {backdrop: 'static'});
  }

  modelOpen(template: TemplateRef<any>) {
    this.modalRef = this.bsmodalservice.show(template, {backdrop: 'static'});
  }

  close() {
    this.SignSubmit = false;
    this.modalRef.hide();
    this.passwordShow = 'password';
   
    // this.signForm.reset();
    // this.forgotForm.reset();
    // this.loginForm.reset();
    // this.agentForm.reset();
    // this.printForm.reset();
    // this.cancelForm.reset();
    // this.forgotForm.reset();
  }

  loginProcess() {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    const This = this;
    console.log(this.loginForm.value);
    this.loginSubmit = true;
    if (this.loginForm.valid) {
      console.log(this.country.Successfully)
      const userObj = this.loginForm.value;
      this.funcName = 'user_login';
  
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          console.log("in",this.isShow)
          response.data.type = 'user';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
          this.loginResponse = this.country.Successfully;
          setTimeout( function( ) {
            This.loginResponse = undefined;
            This.loginForm.reset();
            This.close();
          }, 1000);
        } else {
          console.log("er",this.country.Failedlogin)
          // this.loginResponse = this.country.Failedlogin;
          this.loginResponse = response.message;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.loginForm);
    }
    console.log('tested');
    this.isShow = false;
   
  }

  MatchPassword(val: any) {
    return new Promise(resolve => {
      if (val.value === this.signForm.value.password) {
        resolve(null);
      } else {
        resolve({ 'match': false });
      }
    });
  }


  signProcess() {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    const This = this;
    console.log('terms123',this.signForm.value.terms);
    
    this.SignSubmit = true;
    if (this.signForm.valid) {
      const userObj = this.signForm.value;
      userObj.country_code = localStorage.getItem('userCountry').replace(/[\""]/g, '');
      this.funcName = 'user_signup';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          response.data.type = 'user';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
         
          this.signResponse = this.country.Successfully;
          setTimeout( function() {
            This.signResponse = undefined;
            This.signForm.reset();
            This.close();
          }, 1000);
        } else {
          this.signResponse = response.message;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.signForm);
      if(this.signForm.value.terms == false){
        this.termsError ="true";
      }
    }
    console.log('tested123');
   
  }

  forgotProcess() {
    this.forgotSubmit = true;
    const This = this;
    if (this.forgotForm.valid) {
      const userObj = { phone_no:this.forgotForm.value.emailphoneNo };
      this.funcName = 'confirmUserMobileno';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        if (response.status === 'success') {
          if(response.code === 1) {
          this.sendOtp(this.forgotForm.value.emailphoneNo, response.url);       
          }
          if(response.code === 2 ||response.code === 0) {
          this.forgotResponse = response;
          }
  

         // this.forgotForm.reset();
         // This.forgotSubmit = false;

          //this.modalRef.hide();
          this.loading = false;
        } else {
          this.loading = false;
           this.forgotResponse = response;
           this.forgotSubmit=false;  

        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.forgotForm);
    }
  }
   sendOtp(mob:any, url:any) {
    const This = this;

    this.phoneRecaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-sign-in-recaptcha', {
      'size': 'invisible',
      'callback': function (response) {
      // reCAPTCHA solved - will proceed with submit function
      },
      'expired-callback': function () {
      // Reset reCAPTCHA?
      this.phoneRecaptchaVerifier.clear();
      }
      });
      This.loading = true;
    const phoneNumber=This.currencyDetails.code+mob;
    firebase.auth().signInWithPhoneNumber(phoneNumber, this.phoneRecaptchaVerifier).then(function(confirmationResult){
      var code = prompt(`We have send a code to ${phoneNumber}, please enter it here`, "");
      if (code) {
        confirmationResult.confirm(code).then(function (result) {
         This.forgotResponse ={status:'success',message:'please wait verified'};
         This.router.navigateByUrl(url);
         This.modalRef.hide();
         This.forgotSubmit=false;  
          // User signed in successfully.
          // Reset reCAPTCHA?
          // ...
        }).catch(function (error) {
          // User couldn't sign in (bad verification code?)
          // Reset reCAPTCHA?
          // ...
          This.forgotResponse ={status:'error',message:error.message};
          This.forgotSubmit=false;

        });
      }
    }).catch(function(error){
      console.log('hgh',error);
      This.forgotResponse ={status:'error',message:error.message};   
      This.forgotSubmit=false; 

    });
    This.loading = false;
  }

  printProcess() {
    const This = this;
    this.printSubmit = true;
    if (this.printForm.valid) {
      const printObj = this.printForm.value;
      printObj.bookingID = this.bookrevReplace(printObj.bookingID);
      this.funcName = 'print_ticket';
      this.loading = true;
      this.service.post_data(this.funcName, printObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          this.printResponse = response;
          setTimeout( function() {
            This.printForm.reset();
            This.modalRef.hide();
            This.printResponse.status = '';
            This.printResponse.message = '';
            This.printSubmit = false;
            This.printResponse = undefined;
          }, 1000);
        } else {
          this.printResponse = response;
        }
      }, (error) => {

      });
    }
  }

  agentProcess() {
    const This = this;
    console.log(this.agentForm.value);
    this.agentSubmit = true;
    if (this.agentForm.valid) {
      const userObj = this.agentForm.value;
      this.funcName = 'agent_login';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          response.data.type = 'agent';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
          this.agentResponse = response;
          setTimeout( function() {
            This.agentResponse = undefined;
            This.agentForm.reset();
            This.close();
          }, 1000);
          this.isShow=false;
        } else {
          this.agentResponse = response;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.loginForm);
    }
    console.log('tested');
  }

  cancelProcess() {
    const This = this;
    this.cancelSubmit = true;
    if (this.cancelForm.valid) {
      const cancelObj = this.cancelForm.value;
      cancelObj.bookingID = this.bookrevReplace(cancelObj.bookingID);
      cancelObj['type'] = this.loginDetails.type;
      cancelObj['userId'] = this.loginDetails.id;
      this.funcName = 'cancel_ticket';
      this.loading = true;
      this.service.post_data(this.funcName, cancelObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          this.cancelTicket(response.data);
        } else {
          this.cancelResponse = response;
        }
      }, (error) => {
        this.cancelResponse = 'Something Went wrong';
      });
    } else {
      this.cancelResponse = 'Form Invalid';
    }
  }

  cancelTicket(data: any) {
    const postData = data;
    let url = 'cancel?username=javatest&password=123456';
    console.log(url);
    this.service.online_service(url, 'data=' + JSON.stringify(postData)).subscribe(response => {
        const confirm_result = response;
        console.log(confirm_result);
        if (confirm_result.message === 'Success') {
          this.funcName = 'confirmCancel';
          this.loading = true;
          const confirmData = {'bookingId': postData.bookingCode};
          this.service.post_data(this.funcName, confirmData).subscribe(responseData => {
            this.cancelForm.reset();
            this.close();
            this.loading = false;
            if ( responseData.status === 'success') {
              url = 'confirmcancel?username=javatest&password=123456';
              this.service.online_service(url, 'data=' + JSON.stringify(postData)).subscribe(res => {
                if (res.message === 'Success') {
                  console.log('enter into cancel page');
                  this.cancelSubmit = false;
                  this.cancelForm.reset();
                  this.close();
                  this.goToPage('account');
                } else {
                  this.errorResp('Confirmation cancell failed. Please contact our support Team');
                }
              }, (error) => {
                this.errorResp('Something went wrong. Please feel free to contact');
              });
            } else {
              this.errorResp(responseData.message);
            }
          }, (error) => {
            this.errorResp('Something went wrong');
          });
        }
    }, (error) => {
      this.errorResp('Cancel Request failed. please try agin later');
    });
  }

  errorResp(message: string) {
    this.cancelResponse.status = 'error';
    this.cancelResponse.message = message;
    const This = this;
    setTimeout( function() {
      This.cancelForm.reset();
      This.modalRef.hide();
      This.cancelResponse.status = '';
      This.cancelResponse.message = '';
      This.cancelSubmit = false;
      This.cancelResponse = undefined;
    }, 1000);
  }

  getErrors(FormAttr) {
    Object.keys(FormAttr.controls).forEach(key => {
      console.log(key);
      const controlErrors: ValidationErrors = FormAttr.get(key).errors;
      if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
    });
  }

  logout() {
    const userData = this.service.getLocalStorageItem('userData');
    this.service.removeLocalStorageItem(userData);
    this.subjectService.sendLoginData('logged_out');
    this.router.navigate(['../index']);
  }
  istoggle() {

    this.isShow = !this.isShow;
  }
  public socialSignIn(socialPlatform: string) {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    let socialPlatformProvider: any;
    const This = this;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      
      (userData) => {
        this.funcName = 'social_login';
          this.loading = true;
          this.service.post_data(this.funcName, userData).subscribe(responseData => {
            this.loading = false;
            if(responseData.status === 'success') {
              responseData.data.type = 'user';
              this.service.setLocalStorageItem('userData', JSON.stringify(responseData.data));
              this.subjectService.sendLoginData('logged_in');
              this.socialResponse = this.country.Successfully;
              This.close();
            } else {
              this.errorResp(this.country.Failedlogin);
            }
          }, (error) => {
            this.errorResp('Something went wrong');
          });
      }).catch(function (data) {
      // Handle error here
      });
  }

  bookrevReplace(bookID: any) {
    const str = bookID;
    return str.replace(/^.{2}/g, 'TG');
  }
  toogle(value) {
    this.vs.getNavbar(value);
    this.currentNav=value;

  }
  onClick() {
    if (this.passwordShow === 'password') {
      this.passwordShow = 'text';
      this.show = true;
    } else {
      this.passwordShow = 'password';
      this.show = false;
    }
  }

}
