import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { apiConfig } from '../../environments/server.config';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Auth': 'my_key'
  })
};

const httplive = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
  })
};

@Injectable({
  providedIn: 'root'
})

export class WebService {
  private handleError: HandleError;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('WebService');
  }

  post_data(url: string, data) {
    return this.http.post(`${apiConfig + url}`, data, httpOptions).pipe(map((response: any) => response));
  }
  get_data(url) {
    return this.http.get(`${apiConfig + url}`, httpOptions).pipe(map((response: any) => response));
  }
  setLocalStorageItem(id: string, data: string) {
    localStorage.setItem(id, data);
  }
  getLocalStorageItem(data: string): string {
    return localStorage.getItem(data);
  }
  removeLocalStorageItem(data) {
    localStorage.removeItem(data);
    localStorage.clear();
  }

  online_service(url: string, data = null) {
    const sendData = { 'url': url, 'data': data };
    const web_url = 'online_service';
    return this.http.post(`${apiConfig + web_url}`, sendData, httpOptions).pipe(map((response: any) => response));
  }
  ObjArray(data: any) {
    if (data instanceof Array !== true) {
      if (data !== undefined) {
        return new Array(data);
      } else {
        return undefined;
      }
    } else {
      return data;
    }
  }
}
