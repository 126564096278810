import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parceltracking',
  templateUrl: './parceltracking.component.html',
  styleUrls: ['./parceltracking.component.scss']
})
export class ParceltrackingComponent implements OnInit {
  texto = 'Wenceslau Braz - Cuidado com as cargas';
  lat = -23.8779431;
  lng = -49.8046873;
  zoom = 15;
  constructor() {}

  ngOnInit() {}
}
