import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { moduleRouting } from './home.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material'

/* BOOTSTRAP-COMPONENTS */

import { BsDatepickerModule } from 'ngx-bootstrap';

import { defineLocale } from 'ngx-bootstrap/chronos';

import { enGbLocale } from 'ngx-bootstrap/locale';

defineLocale('engb', enGbLocale);

import { DateSet } from './../provider/dateSet.pipe';
import { Date24 } from '../provider/date24.pipe';
import { SearchPipe } from './../provider/search.pipe';

import { LoaderComponent } from './../loader/loader.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CountoModule } from 'angular2-counto';
import { AgmDirectionModule } from 'agm-direction';

/* CUSTOM-COMPONENTS */

import { IndexComponent } from './index/index.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { BookingComponent } from './booking/booking.component';
import { AccountComponent } from './account/account.component';
import { SuccessComponent } from './success/success.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TicketComponent } from './ticket/ticket.component';
import { ParceltrackingComponent } from './parceltracking/parceltracking.component';
import { AgmCoreModule } from '@agm/core';
import { ContactComponent } from './contact/contact.component';
import { BustrackingComponent } from './bustracking/bustracking.component';
import { TranslateModule } from '@ngx-translate/core';
import { IndexagentComponent } from './indexagent/indexagent.component';
@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule,
    moduleRouting,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    InfiniteScrollModule,
    CountoModule,
    AgmCoreModule,
    AgmDirectionModule,
    MatSlideToggleModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    IndexComponent,
    SearchresultComponent,
    BookingComponent,
    AccountComponent,
    TermsComponent,
    SuccessComponent,
    LoaderComponent,
    DateSet,
    Date24,
    SearchPipe,
    ChangepasswordComponent,
    PrivacyComponent,
    TicketComponent,
    ParceltrackingComponent,
    ContactComponent,
    BustrackingComponent,
    IndexagentComponent
  ]
})
export class HomeModule { }
