import { Component, OnInit } from '@angular/core';
import { WebService } from './../../provider/web.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  agencyList: any;
  loader: boolean;
  constructor(
    private service: WebService
  ) { }

  ngOnInit() {
    this.getAgencyList();
    this.loader = false;
  }
  getAgencyList() {
    this.loader = true;
    const passData = {
      countrycode : JSON.parse(this.service.getLocalStorageItem('userCountry')),    
   };
    this.service.post_data('getAgencyList',passData).subscribe((data) => {
      this.loader = false;
      if (data.code === 1) {
        this.agencyList = data.data;
        console.log(this.agencyList);
      } else {
        this.agencyList = '';
      }
    });
  }
}
