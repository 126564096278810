import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancelsuccess',
  templateUrl: './cancelsuccess.component.html',
  styleUrls: ['./cancelsuccess.component.scss']
})
export class CancelsuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
