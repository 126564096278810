import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time24' })
export class Date24 implements PipeTransform {
  transform(timeString: string ) {
      const [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split(':');
       if (hours === '12') {
        hours = '00';
      }      
      return `${hours}:${minutes}`;
    }
  }
