import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchPipe', pure: false })
export class SearchPipe implements PipeTransform {
  transform(result, filters ) {
    // console.log(filters);
    return result.filter(function(item) {
        //console.log(item);
        let index;
        let time_state: any;
        let state = 1;
        if ( filters.travels.length !== 0 || filters.drop_point.length !== 0 || filters.pick_point.length !== 0
            || filters.rate.length !== 0 || filters.time.length !== 0 || filters.type.length !== 0) {


            if (filters.travels.length !== 0) {
                state = 0;
                index = filters.travels.indexOf(item.operatorCode);
                if (index !== -1) {
                    state = 1;
                }
            }

            if (filters.pick_point.length !== 0 && state === 1) {
                state = 0;
                if (item.boardingPointDetails instanceof Array) {
                    let pick_state = false;
                    item.boardingPointDetails.forEach((items: any) => {
                        index = filters.pick_point.indexOf(items.name);
                        if (index !== -1) {
                            pick_state = true;
                        }
                    });

                    if ( pick_state !== false ) {
                        state = 1;
                    }
                } else {
                    if (item.boardingPointDetails !== undefined) {
                        index = filters.pick_point.indexOf(item.boardingPointDetails.name);
                        if (index !== -1) {
                            state = 1;
                        }
                    }
                }
            }

            if (filters.drop_point.length !== 0 && state === 1) {
                state = 0;
                if (item.droppingPointDetails instanceof Array) {
                    let pick_state = false;
                    item.droppingPointDetails.forEach((items: any) => {
                        index = filters.drop_point.indexOf(items.name);
                        if (index !== -1) {
                            pick_state = true;
                        }
                    });

                    if ( pick_state !== false ) {
                        state = 1;
                    }
                } else {
                    if (item.droppingPointDetails !== undefined) {
                        index = filters.drop_point.indexOf(item.droppingPointDetails.name);
                        if (index !== -1) {
                            state = 1;
                        }
                    }
                }
            }

            if (filters.time.length !== 0 && state === 1) {
                state = 0;
                const time_split = item.departureTime.split(':');
                const date1 = new Date();
                date1.setHours(time_split[0]);
                date1.setMinutes(time_split[1]);
                const bus_time = date1.getTime();
                const item_list = [];
                time_state = false;
                filters.time.filter(times => {
                     const date_list = times.split('-');
                    // tslint:disable-next-line:radix
                    const start_time = parseInt(date_list[0]);
                    // tslint:disable-next-line:radix
                    const end_time = parseInt(date_list[1]);
                    if (start_time < bus_time && bus_time  < end_time) {
                        time_state = true;
                    }
                });

                if (time_state !== false) {
                    state = 1;
                }

            }

            if (filters.rate.length !== 0 && state === 1) {
                state = 0;
                index = filters.rate.indexOf(item.rate);
                if (index !== -1) {
                    console.log(item);
                    state = 1;
                }
            }

            if (filters.type.length !== 0 && state === 1) {
                state = 0;
                const str = item.busType;
                let type_state = false;
                filters.type.forEach((items: any) => {
                    if (items === 'A/C' || items === 'Sleeper') {
                        if (items === 'A/C') {
                            index = str.includes('Non');
                            if (index !== true) {
                                type_state = true;
                            }
                        } else {
                            index = str.includes('Semi');
                            if (index !== true) {
                                type_state = true;
                            }
                        }
                    } else {
                        index = str.includes(items);
                        if (index === true) {
                            type_state = true;
                        }
                    }
                });

                if (type_state !== false) {
                    state = 1;
                }
            }


            if (state === 1) {
                return result;
            }


        } else {
            return result;
        }
    });
    return result;
  }
}
