import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
declare var google;

@Component({
  selector: "app-bustracking",
  templateUrl: "./bustracking.component.html",
  styleUrls: ["./bustracking.component.scss"]
})
export class BustrackingComponent implements OnInit {
  texto: string = "Wenceslau Braz - Cuidado com as cargas";
  lat: number;
  lng: number;
  zoom: number = 15;
  address: any;
  trackData: any;
  public origin: any;
  public destination: any;
  private geoCoder;
  distanceValue: any;
  travelTime: any;
  constructor(private router: Router, private route: ActivatedRoute) {
    const data = decodeURIComponent(this.route.snapshot.params["id"]);
    this.trackData = JSON.parse(data);
    this.lat = Number(this.trackData.lat);
    this.lng = Number(this.trackData.lng);
    console.log(this.trackData);
    this.geoCoder = new google.maps.Geocoder();
  }

  ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(position);
        if (position) {
          this.getTrackingData(
            position.coords.latitude,
            position.coords.longitude
          );
        }
      });
    } else {
      alert("Please Enable Geolocation to track Bus.");
    }
  }
  getTrackingData(lat, lng) {
    this.origin = { lat: lat, lng: lng };
    this.destination = { lat: this.lat, lng: this.lng };
    this.getAddress();
    this.computeDistance();
  }
  getAddress() {
    this.geoCoder.geocode(
      { location: { lat: this.lat, lng: this.lng } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            window.alert("Unable to fetch location Data");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }
  computeDistance() {
    // computeDistance
    const origin1 = new google.maps.LatLng(this.origin.lat, this.origin.lng);

    const destinationB = new google.maps.LatLng(
      this.destination.lat,
      this.destination.lng
    );

    // tslint:disable-next-line: max-line-length
    return new google.maps.DistanceMatrixService().getDistanceMatrix(
      {
        origins: [origin1],
        destinations: [destinationB],
        travelMode: "DRIVING"
      },
      (results: any) => {
        console.log(
          "resultados distancia (mts) -- ",
          results.rows[0].elements[0]
        );
        this.distanceValue = results.rows[0].elements[0].distance.text;
        this.travelTime = results.rows[0].elements[0].duration.text;
        console.log(this.distanceValue, this.travelTime);
      }
    );
  }
}
