

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebService } from './../../provider/web.service';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';
import { SubjectService } from './../../provider/subject.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
declare const $: any;
declare const FlutterwaveCheckout: any;
declare var Razorpay: any;
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  bookDetails: any[];
  loginDetails: any;
  funcName: any;
  loading: boolean;
  profileSubmit: boolean;
  passwordSubmit: boolean;
  private current_page = 0;
  public profileForm: FormGroup;
  public passwordForm: FormGroup;
  mobnumPattern = '^((\\+?)|0)?[0-9]{0,20}$';
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  profileMsg: any;
  passwordMsg: any;
  ageDate: any;
  bookinfoData: any;
  loader: boolean;
  modalRef: BsModalRef;
  amount_error: any;
  amount: any;
  walletData: any;
  walletMsgSuc: any;
  walletMsgEr: any;
  detailedError: any;
  @ViewChild('wallet') private login: TemplateRef<HTMLElement>;
  imgURL: any;
  message: string;
  imagePath: any;
  realImageURL: any;
countryCode:any;
  country: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public subjectService: SubjectService,
    private bsmodalservice: BsModalService,
    private translate: TranslateService
  ) {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    this.countryCode = JSON.parse(localStorage.getItem('userCountry'));
    console.log(this.countryCode)
    this.loading = false;
    this.profileSubmit = false;
    this.bookDetails = [];
    this.ageDate = new Date();
    this.ageDate.setFullYear(this.ageDate.getFullYear() - 12);
    console.log(this.ageDate);
    this.loader = false;
    
  }
  refId = '';
  ngOnInit() {
    this.generateRef();
    $.getScript( 'https://checkout.flutterwave.com/v3.js', function( data, textStatus, jqxhr ) {
    console.log( data ); // Data returned
    console.log( textStatus ); // Success
    console.log( jqxhr.status ); // 200
    console.log( "Load was performed." );
    // data.FlutterwaveCheckout({}); // ???
  });
    this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));
    const mydate = new Date(this.loginDetails.dob);
    this.bookingDetails();
    this.walletAmount();
    if (this.loginDetails.type !== 'agent') {
      this.profileForm = this.formBuilder.group({
        'name': [this.loginDetails.name, Validators.compose([Validators.required])],
         profile_pic: [''],
        // tslint:disable-next-line:max-line-length
        'mob': [this.loginDetails.mob, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this.mobnumPattern)])],
        // tslint:disable-next-line:max-line-length
        'username': [this.loginDetails.username, Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)])]
      });
    } else {
      this.profileForm = this.formBuilder.group({
        'name': [this.loginDetails.name, Validators.compose([Validators.required])],
        profile_pic: [''],
        // tslint:disable-next-line:max-line-length
        'mob': [this.loginDetails.phone, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this.mobnumPattern)])],
        // tslint:disable-next-line:max-line-length
        'username': [this.loginDetails.email_id, Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)])]
      });
    }
    this.passwordForm = this.formBuilder.group({
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'newpassword': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'cpassword': ['', Validators.compose([Validators.required]), this.MatchPassword.bind(this)]
    });
  }


  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  bookingDetails() {
    const sendData = { 'userId': this.loginDetails.id, 'type': this.loginDetails.type };
    this.funcName = 'getMyTrips';
    this.loading = true;
    this.service.post_data(this.funcName, sendData).subscribe(response => {
      this.loading = false;
      if (response.status === 'success' && response.data.length > 0) {
        //   this.bookDetails = this.bookDetails.concat(response.data);
        this.bookDetails = response.data;
        // ++this.current_page;
      } else {
    
        this.bookDetails = this.bookDetails.length > 0 ? this.bookDetails : [];
      }
    }, (error) => {

    });
  }
  getBookingDetails(id) {
    console.log(id);
    const passData = { 'bookingCode': id };
    this.service.post_data('getBookingDetails', passData).subscribe((response) => {
      console.log(response);
    });
  }
  check_time(time: string, type: string) {
    const timeArray = time.split(' - ');
    if (type === 'start') {
      return timeArray[0];
    } else {
      return timeArray[1];
    }
  }

  onScroll() {

    // this.bookingDetails();
  }
  updateImage() {

  }

  profileProcess() {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    this.profileSubmit = true;
    if (this.profileForm.valid) {
      const pass_data = this.profileForm.value;
      const This = this;
      let postData: any;
      if (this.loginDetails.type !== 'agent') {
        const dob = this.formatDate(pass_data.dob);
        postData = {
          'name': pass_data.name,
          'username': pass_data.username,
          'mob': pass_data.mob,
          'userId': this.loginDetails.id,
          "country_code":this.countryCode,
          "gender":"",
          "dob":"1994-10-04"
        };

        this.funcName = 'profile_update';
      } else {
        postData = {
          'name': pass_data.name,
          'username': pass_data.username,
          'mob': pass_data.mob,
          'userId': this.loginDetails.id,
          "country_code":this.countryCode,
          "gender":"",
          "dob":"1994-10-04"

        };

        this.funcName = 'agent_update';
      }



      this.loading = true;
      this.service.post_data(this.funcName, postData).subscribe(response => {
        this.loading = false;
        if (response.status === 'success') {
          this.profileMsg = '<small class="text-info">' + this.country.profilesuccess + '</smal>';
          if (this.loginDetails.type !== 'agent') {
            response.data.type = 'user';
          } else {
            response.data.type = 'agent';
          }
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
        } else {
          this.profileMsg = '<small class="text-danger">' + this.country.failed  + '</smal>';
        }
        setTimeout(function () {
          This.profileMsg = '';
        }, 3000);
      }, (error) => {
        this.profileMsg = '<small class="text-danger"> Something went wrong. Feel free to contact us .</smal>';
      });
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  MatchPassword(val: any) {
    return new Promise(resolve => {
      if (val.value === this.passwordForm.value.newpassword) {
        resolve(null);
      } else {
        resolve({ 'match': false });
      }
    });
  }

  passwordProcess() {
    this.translate.get('warnings').subscribe((data:any)=> {
      this.country = data;
    });
    this.passwordSubmit = true;
    if (this.passwordForm.valid) {
      const password_data = this.passwordForm.value;
      const This = this;

      const postData = {
        'password': password_data.password,
        'newpassword': password_data.newpassword,
        'userId': this.loginDetails.id,
        'type': this.loginDetails.type
      };

      this.funcName = 'password_update';


      this.loading = true;
      this.service.post_data(this.funcName, postData).subscribe(response => {
        this.loading = false;
        if (response.status === 'success') {
          this.passwordMsg = '<small class="text-info">' + this.country.invalid + '</smal>';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
        } else {
          this.passwordMsg = '<small class="text-danger">' + this.country.invalid + '</smal>';
        }
        setTimeout(function () {
          This.passwordMsg = '';
        }, 3000);
      }, (error) => {
        this.passwordMsg = '<small class="text-danger"> Something went wrong. Feel free to contact us .</smal>';
      });
    } else {
      this.getErrors(this.passwordForm);
    }
  }

  getErrors(FormAttr) {
    Object.keys(FormAttr.controls).forEach(key => {
      console.log(key);
      const controlErrors: ValidationErrors = FormAttr.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  getBookinfo(id: string, index: number) {
    this.loader = true;
    const passData = { 'bookingCode': id };
    this.service.post_data('getBookingDetails', passData).subscribe(response => {
      this.loader = false;
      console.log(response);
      if (response.code === 1) {
        this.detailedError = undefined;
        this.bookDetails[index].bookinfoData = response;
        if (this.bookDetails[index].bookinfoData.passengerDetails instanceof Array !== true) {
          this.bookDetails[index].bookinfoData.passengerDetails = new Array(this.bookDetails[index].bookinfoData.passengerDetails);
        }
        const searchObj = encodeURIComponent(JSON.stringify(this.bookDetails[index].bookinfoData));
        this.router.navigate(['ticket/' + searchObj]);
      } else {
        this.detailedError = '<small class="text-danger">' + response.errorDesc + '</small>';
      }
    }, (error) => {
      console.log(error);
    });

  }

  modelOpen(template: TemplateRef<any>) {
    console.log('called');
    this.modalRef = this.bsmodalservice.show(template, { backdrop: 'static' });
  }

  close() {
    this.modalRef.hide();
  }

  addFund() {
    //this.payment_success_temp();
    const This = this;
    console.log(this.amount);
    if (this.amount !== undefined) {
      if (!isNaN(parseInt(this.amount, 10))) {
        this.amount_error = undefined;
        console.log(this.amount);
        this.close();
     
       //flutter start
       console.log('Paying...');
       FlutterwaveCheckout({
          //public_key: "FLWPUBK-5ec3194fccdace2fda1245d6848d5cab-X",
         public_key: "FLWPUBK_TEST-31d61a13026483fc38f15f0e90232374-X",
         
         tx_ref: this.refId,
         amount:This.amount,
         currency: "NGN",
         payment_options: "card",
         meta: {
           consumer_id: 23,
           consumer_mac: "92a3-912ba-1192a",
         },
         customer: {
           email: this.loginDetails.email_id,
           phone_number: this.loginDetails.phone,
           name:this.loginDetails.name,
         },
    
         callback: function (data) {
           console.log('data',data)
          if(data.status=="successful"){
           
           
            This.payment_success_temp(data.transaction_id);
   
          }
           
       
         },
         customizations: {
           title: "Mogo Bus",
           description: "Payment for Mogo Bus Booking",
           //logo: "./../../../assets/imgs/logo.png",
           logi:"https://mogobus.co/src/assets/imgs/logo.png",
         }
       });

       //flutter end 

      } else {
        this.amount_error = 'Input valid amount';
      }
    } else {
      this.amount_error = 'Amount required';
    }
    console.log(this.amount);
  }
  payment_success_temp(transId) {
    this.loading = true;
    const postData = { 'userId': this.loginDetails.id, 'amount': this.amount ,'token': transId};
  
    this.funcName = 'addFund';
    this.service.post_data(this.funcName, postData).subscribe(responseData => {
      this.loading = false;
      if (responseData.status === 'success') {
       this.walletMsgSuc = responseData.message;
       this.walletAmount();
      } else {
        console.log('failed');
      }
    }, (error) => {
      this.loading = false;
    });
  }

  payment_success(pay_id: string) {
    this.loading = true;
    const postData = { 'userId': this.loginDetails.id, 'amount': this.amount, 'token': pay_id };
    this.funcName = 'addFund';
    this.service.post_data(this.funcName, postData).subscribe(responseData => {
      this.loading = false;
      if (responseData.status === 'success') {
  
        this.walletAmount();
      } else {
        console.log('else',responseData.message)
        this.walletMsgEr = responseData.message;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  walletAmount() {
    this.loading = true;
    const postData = { 'userId': this.loginDetails.id };
    this.funcName = 'walletAmount';
    this.service.post_data(this.funcName, postData).subscribe(responseData => {
      this.loading = false;
      if ( responseData.status === 'success') {
        this.walletData = responseData.data;
        
      } else {
        
      }
    }, (error) => {
      this.loading = false;
    });
  }

  bookReplace(bookID: any) {
    const str = bookID;
    return str.replace(/^.{2}/g, 'TR');
  }
  preview(files) {
    this.imgURL = null;
    console.log(files)
    if ( files.length === 0 ) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      if (this.imgURL) {
        const  passData = {
          "userId" :this.loginDetails.id,
          "profile_pic" :  this.imgURL
          };
          this.service.post_data('profile_pic_update', passData).subscribe((data:any) => {
            this.service.setLocalStorageItem('userData', JSON.stringify(data.data));
            this.subjectService.sendLoginData('logged_in');
          })

        this.profileForm.patchValue({
          profile_pic: this.imgURL
        });
        console.log(this.profileForm.value)
      } else {
        this.profileForm.patchValue({
          profile_pic: this.realImageURL
        });
      }
    }
  }
  generateRef() {
    this.refId = `PAYVtestuser1-${Date.now()}`;
  }
  paymentCancel(e) {
    console.log('Why canceled?',e);
    this.generateRef(); // Generate Ref for next transcation
  }
  paymentDone(res) {
    this.generateRef(); // Generate Ref for next transcation
    console.log('My response', res);
  }

}
