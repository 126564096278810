import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { SearchresultComponent } from "./searchresult/searchresult.component";
import { BookingComponent } from "./booking/booking.component";
import { AccountComponent } from "./account/account.component";
import { SuccessComponent } from "./success/success.component";
import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { AuthGuard } from "./../provider/auth.guard";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { TicketComponent } from "./ticket/ticket.component";
import { ParceltrackingComponent } from "./parceltracking/parceltracking.component";
import { ContactComponent } from "./contact/contact.component";
import { BustrackingComponent } from "./bustracking/bustracking.component";
const ModuleRoutes: Routes = [
  { path: "index", component: IndexComponent },
  { path: "searchresult/:id", component: SearchresultComponent },
  { path: "booking/:data", component: BookingComponent },
  { path: "account", component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: "success/:bookId/:amount",
    component: SuccessComponent,
    canActivate: [AuthGuard]
  },
  { path: "changepassword/:id", component: ChangepasswordComponent },
  { path: "terms", component: TermsComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "ticket/:id", component: TicketComponent },
  { path: "parceltracking", component: ParceltrackingComponent },
  { path: "contact", component: ContactComponent },
  { path: "bustracking/:id", component: BustrackingComponent }
];

export const moduleRouting: ModuleWithProviders = RouterModule.forChild(
  ModuleRoutes
);
